import { graphql } from "../graphql";
import query from "./query.gql";
import { IRepoErrors } from "../errors";
import { FetchResult } from "@apollo/client";
import { graphqlExtractGraphqlErrors } from "@/v2/util/graphql-extract-graphql-errors";
import { graphqlRemapValidationErrors } from "@/v2/util/graphql-remap-validation-errors";
import { UserManagementTenantRoleEnum } from "@/v2/enum/user-management";

export async function fetchTenantProfile(): Promise<IFetchTenantProfileResponse> {
  let response: FetchResult<any, Record<string, any>, Record<string, any>>;
  const errors: IRepoErrors<ValidationErrors> = {};

  try {
    response = await graphql.query({
      query,
    });
  } catch (error) {
    errors.networkError = true;
    throw new FetchTenantProfileError(errors);
  }

  if (response.errors) {
    errors.graphqlError = graphqlExtractGraphqlErrors(response);
    errors.validationError = graphqlRemapValidationErrors(response);
    throw new FetchTenantProfileError(errors);
  }

  return response.data.tenantProfile;
}

export class FetchTenantProfileError extends Error {
  errors: IRepoErrors<ValidationErrors>;

  constructor(errors: IRepoErrors<ValidationErrors>) {
    super("Error in repo: FetchTenantProfile");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}

type ValidationErrors = {};

interface IFetchTenantProfileResponse {
  firstName: string;
  lastName: string;
  fullName: string;
  email: string;
  phone: string;
  organizations: Array<AccessibleOrganization>;
}

interface AccessibleOrganization {
  name: string;
  role: UserManagementTenantRoleEnum;
  businesses: Array<AccessibleBusiness>;
}

interface AccessibleBusiness {
  name: string;
  logo: string | null;
  outlets: Array<string>;
}
