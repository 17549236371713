import { GraphQLError } from "graphql";

export function graphqlExtractValidationErrors(
  graphqlError: Pick<GraphQLError, "extensions"> | undefined
): IGraphQLExtractErrorsResult {
  const errors: Record<string, string> = {};
  // @ts-ignore
  const validation: Record<string, Array<string>> = graphqlError?.extensions
    ?.validation;

  validation &&
    Object.entries(validation).forEach(
      ([key, value]) => (errors[key] = value[0])
    );

  return errors;
}

export type IGraphQLExtractErrorsResult = Record<string, string>;
