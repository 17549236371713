export enum UserManagementTenantRoleEnum {
  ACCOUNT_OWNER = "ACCOUNT_OWNER",
  ADMINISTRATOR = "ADMINISTRATOR",
  FINANCE = "FINANCE",
  MANAGER = "MANAGER",
  MARKETING = "MARKETING",
  STAFF = "STAFF",
  SUPER_ADMIN = "SUPER_ADMIN",
  HOSTESS = "HOSTESS",
  WAITER = "WAITER",
}
