export default [
  {
    abbr: "AF",
    code: "93",
    name: "Afghanistan",
  },
  {
    abbr: "AL",
    code: "355",
    name: "Albania",
  },
  {
    abbr: "DZ",
    code: "213",
    name: "Algeria",
  },
  {
    abbr: "AS",
    code: "1 684",
    name: "American Samoa",
  },
  {
    abbr: "AD",
    code: "376",
    name: "Andorra",
  },
  {
    abbr: "AO",
    code: "244",
    name: "Angola",
  },
  {
    abbr: "AI",
    code: "1 264",
    name: "Anguilla",
  },
  {
    abbr: "AG",
    code: "1268",
    name: "Antigua and Barbuda",
  },
  {
    abbr: "AR",
    code: "54",
    name: "Argentina",
  },
  {
    abbr: "AM",
    code: "374",
    name: "Armenia",
  },
  {
    abbr: "AW",
    code: "297",
    name: "Aruba",
  },
  {
    abbr: "AU",
    code: "61",
    name: "Australia",
    mask: "#-####-####",
    placeholder: "9-9999-9999",
  },
  {
    abbr: "AT",
    code: "43",
    name: "Austria",
  },
  {
    abbr: "AZ",
    code: "994",
    name: "Azerbaijan",
  },
  {
    abbr: "BS",
    code: "1 242",
    name: "Bahamas",
  },
  {
    abbr: "BH",
    code: "973",
    name: "Bahrain",
    mask: "####-####",
    placeholder: "9999-9999",
  },
  {
    abbr: "BD",
    code: "880",
    name: "Bangladesh",
    mask: "##-###-###",
    placeholder: "99-999-999",
  },
  {
    abbr: "BB",
    code: "1 246",
    name: "Barbados",
  },
  {
    abbr: "BY",
    code: "375",
    name: "Belarus",
  },
  {
    abbr: "BE",
    code: "32",
    name: "Belgium",
  },
  {
    abbr: "BZ",
    code: "501",
    name: "Belize",
  },
  {
    abbr: "BJ",
    code: "229",
    name: "Benin",
  },
  {
    abbr: "BM",
    code: "1 441",
    name: "Bermuda",
  },
  {
    abbr: "BT",
    code: "975",
    name: "Bhutan",
  },
  {
    abbr: "BO",
    code: "591",
    name: "Bolivia, Plurinational State of",
  },
  {
    abbr: "BA",
    code: "387",
    name: "Bosnia and Herzegovina",
  },
  {
    abbr: "BW",
    code: "267",
    name: "Botswana",
  },
  {
    abbr: "BR",
    code: "55",
    name: "Brazil",
    mask: "(##) 9 ####-####",
    placeholder: "(11) 9 9999-9999",
  },
  {
    abbr: "IO",
    code: "246",
    name: "British Indian Ocean Territory",
  },
  {
    abbr: "BN",
    code: "673",
    name: "Brunei Darussalam",
  },
  {
    abbr: "BG",
    code: "359",
    name: "Bulgaria",
  },
  {
    abbr: "BF",
    code: "226",
    name: "Burkina Faso",
  },
  {
    abbr: "BI",
    code: "257",
    name: "Burundi",
  },
  {
    abbr: "KH",
    code: "855",
    name: "Cambodia",
  },
  {
    abbr: "CM",
    code: "237",
    name: "Cameroon",
  },
  {
    abbr: "CA",
    code: "1",
    name: "Canada",
    mask: "(###) ###-####",
    placeholder: "(999) 999-9999",
  },
  {
    abbr: "CV",
    code: "238",
    name: "Cape Verde",
  },
  {
    abbr: "KY",
    code: "345",
    name: "Cayman Islands",
  },
  {
    abbr: "CF",
    code: "236",
    name: "Central African Republic",
  },
  {
    abbr: "TD",
    code: "235",
    name: "Chad",
  },
  {
    abbr: "CL",
    code: "56",
    name: "Chile",
  },
  {
    abbr: "CN",
    code: "86",
    name: "China",
  },
  {
    abbr: "CX",
    code: "61",
    name: "Christmas Island",
  },
  {
    abbr: "CC",
    code: "61",
    name: "Cocos (Keeling) Islands",
  },
  {
    abbr: "CO",
    code: "57",
    name: "Colombia",
  },
  {
    abbr: "KM",
    code: "269",
    name: "Comoros",
  },
  {
    abbr: "CG",
    code: "242",
    name: "Congo",
  },
  {
    abbr: "CD",
    code: "243",
    name: "Congo, The Democratic Republic of the",
  },
  {
    abbr: "CK",
    code: "682",
    name: "Cook Islands",
  },
  {
    abbr: "CR",
    code: "506",
    name: "Costa Rica",
  },
  {
    abbr: "CI",
    code: "225",
    name: "Cote d'Ivoire",
  },
  {
    abbr: "HR",
    code: "385",
    name: "Croatia",
  },
  {
    abbr: "CU",
    code: "53",
    name: "Cuba",
  },
  {
    abbr: "CY",
    code: "537",
    name: "Cyprus",
  },
  {
    abbr: "CZ",
    code: "420",
    name: "Czech Republic",
  },
  {
    abbr: "DK",
    code: "45",
    name: "Denmark",
  },
  {
    abbr: "DJ",
    code: "253",
    name: "Djibouti",
  },
  {
    abbr: "DM",
    code: "1 767",
    name: "Dominica",
  },
  {
    abbr: "DO",
    code: "1 849",
    name: "Dominican Republic",
  },
  {
    abbr: "EC",
    code: "593",
    name: "Ecuador",
  },
  {
    abbr: "EG",
    code: "20",
    name: "Egypt",
    mask: "(###) ###-####",
    placeholder: "(999) 999-9999",
  },
  {
    abbr: "SV",
    code: "503",
    name: "El Salvador",
  },
  {
    abbr: "GQ",
    code: "240",
    name: "Equatorial Guinea",
  },
  {
    abbr: "ER",
    code: "291",
    name: "Eritrea",
  },
  {
    abbr: "EE",
    code: "372",
    name: "Estonia",
  },
  {
    abbr: "ET",
    code: "251",
    name: "Ethiopia",
  },
  {
    abbr: "FK",
    code: "500",
    name: "Falkland Islands (Malvinas)",
  },
  {
    abbr: "FO",
    code: "298",
    name: "Faroe Islands",
  },
  {
    abbr: "FJ",
    code: "679",
    name: "Fiji",
  },
  {
    abbr: "FI",
    code: "358",
    name: "Finland",
  },
  {
    abbr: "FR",
    code: "33",
    name: "France",
    mask: "(###) ###-###",
    placeholder: "(999) 999-999",
  },
  {
    abbr: "GF",
    code: "594",
    name: "French Guiana",
  },
  {
    abbr: "PF",
    code: "689",
    name: "French Polynesia",
  },
  {
    abbr: "GA",
    code: "241",
    name: "Gabon",
  },
  {
    abbr: "GM",
    code: "220",
    name: "Gambia",
  },
  {
    abbr: "GE",
    code: "995",
    name: "Georgia",
  },
  {
    abbr: "DE",
    code: "49",
    name: "Germany",
  },
  {
    abbr: "GH",
    code: "233",
    name: "Ghana",
  },
  {
    abbr: "GI",
    code: "350",
    name: "Gibraltar",
  },
  {
    abbr: "GR",
    code: "30",
    name: "Greece",
  },
  {
    abbr: "GL",
    code: "299",
    name: "Greenland",
  },
  {
    abbr: "GD",
    code: "1 473",
    name: "Grenada",
  },
  {
    abbr: "GP",
    code: "590",
    name: "Guadeloupe",
  },
  {
    abbr: "GU",
    code: "1 671",
    name: "Guam",
  },
  {
    abbr: "GT",
    code: "502",
    name: "Guatemala",
  },
  {
    abbr: "GG",
    code: "44",
    name: "Guernsey",
  },
  {
    abbr: "GN",
    code: "224",
    name: "Guinea",
  },
  {
    abbr: "GW",
    code: "245",
    name: "Guinea-Bissau",
  },
  {
    abbr: "GY",
    code: "595",
    name: "Guyana",
  },
  {
    abbr: "HT",
    code: "509",
    name: "Haiti",
  },
  {
    abbr: "VA",
    code: "379",
    name: "Holy See (Vatican City State)",
  },
  {
    abbr: "HN",
    code: "504",
    name: "Honduras",
  },
  {
    abbr: "HK",
    code: "852",
    name: "Hong Kong",
  },
  {
    abbr: "HU",
    code: "36",
    name: "Hungary",
  },
  {
    abbr: "IS",
    code: "354",
    name: "Iceland",
  },
  {
    abbr: "IN",
    code: "91",
    name: "India",
    mask: "(####) ###-###",
    placeholder: "(9999) 999-999",
  },
  {
    abbr: "ID",
    code: "62",
    name: "Indonesia",
  },
  {
    abbr: "IR",
    code: "98",
    name: "Iran, Islamic Republic of",
    mask: "(###) ###-####",
    placeholder: "(999) 999-9999",
  },
  {
    abbr: "IQ",
    code: "964",
    name: "Iraq",
    mask: "(###) ###-####",
    placeholder: "(999) 999-9999",
  },
  {
    abbr: "IE",
    code: "353",
    name: "Ireland",
  },
  {
    abbr: "IM",
    code: "44",
    name: "Isle of Man",
  },
  {
    abbr: "IL",
    code: "972",
    name: "Israel",
  },
  {
    abbr: "IT",
    code: "39",
    name: "Italy",
  },
  {
    abbr: "JM",
    code: "1 876",
    name: "Jamaica",
  },
  {
    abbr: "JP",
    code: "81",
    name: "Japan",
  },
  {
    abbr: "JE",
    code: "44",
    name: "Jersey",
  },
  {
    abbr: "JO",
    code: "962",
    name: "Jordan",
    mask: "#-####-####",
    placeholder: "9-9999-9999",
  },
  {
    abbr: "KZ",
    code: "7 7",
    name: "Kazakhstan",
  },
  {
    abbr: "KE",
    code: "254",
    name: "Kenya",
  },
  {
    abbr: "KI",
    code: "686",
    name: "Kiribati",
  },
  {
    abbr: "KP",
    code: "850",
    name: "Korea, Democratic People's Republic of",
  },
  {
    abbr: "KR",
    code: "82",
    name: "Korea, Republic of",
  },
  {
    abbr: "KW",
    code: "965",
    name: "Kuwait",
  },
  {
    abbr: "KG",
    code: "996",
    name: "Kyrgyzstan",
  },
  {
    abbr: "AX",
    code: "",
    name: "land Islands",
  },
  {
    abbr: "LA",
    code: "856",
    name: "Lao People's Democratic Republic",
  },
  {
    abbr: "LV",
    code: "371",
    name: "Latvia",
  },
  {
    abbr: "LB",
    code: "961",
    name: "Lebanon",
    mask: "##-###-###",
    placeholder: "99-999-999",
  },
  {
    abbr: "LS",
    code: "266",
    name: "Lesotho",
  },
  {
    abbr: "LR",
    code: "231",
    name: "Liberia",
  },
  {
    abbr: "LY",
    code: "218",
    name: "Libyan Arab Jamahiriya",
  },
  {
    abbr: "LI",
    code: "423",
    name: "Liechtenstein",
  },
  {
    abbr: "LT",
    code: "370",
    name: "Lithuania",
  },
  {
    abbr: "LU",
    code: "352",
    name: "Luxembourg",
  },
  {
    abbr: "MO",
    code: "853",
    name: "Macao",
  },
  {
    abbr: "MK",
    code: "389",
    name: "Macedonia, The Former Yugoslav Republic of",
  },
  {
    abbr: "MG",
    code: "261",
    name: "Madagascar",
  },
  {
    abbr: "MW",
    code: "265",
    name: "Malawi",
  },
  {
    abbr: "MY",
    code: "60",
    name: "Malaysia",
  },
  {
    abbr: "MV",
    code: "960",
    name: "Maldives",
  },
  {
    abbr: "ML",
    code: "223",
    name: "Mali",
  },
  {
    abbr: "MT",
    code: "356",
    name: "Malta",
  },
  {
    abbr: "MH",
    code: "692",
    name: "Marshall Islands",
  },
  {
    abbr: "MQ",
    code: "596",
    name: "Martinique",
  },
  {
    abbr: "MR",
    code: "222",
    name: "Mauritania",
  },
  {
    abbr: "MU",
    code: "230",
    name: "Mauritius",
  },
  {
    abbr: "YT",
    code: "262",
    name: "Mayotte",
  },
  {
    abbr: "MX",
    code: "52",
    name: "Mexico",
  },
  {
    abbr: "FM",
    code: "691",
    name: "Micronesia, Federated States of",
  },
  {
    abbr: "MD",
    code: "373",
    name: "Moldova, Republic of",
  },
  {
    abbr: "MC",
    code: "377",
    name: "Monaco",
  },
  {
    abbr: "MN",
    code: "976",
    name: "Mongolia",
  },
  {
    abbr: "ME",
    code: "382",
    name: "Montenegro",
  },
  {
    abbr: "MS",
    code: "1664",
    name: "Montserrat",
  },
  {
    abbr: "MA",
    code: "212",
    name: "Morocco",
    mask: "##-####-###",
    placeholder: "99-9999-999",
  },
  {
    abbr: "MZ",
    code: "258",
    name: "Mozambique",
  },
  {
    abbr: "MM",
    code: "95",
    name: "Myanmar",
  },
  {
    abbr: "NA",
    code: "264",
    name: "Namibia",
  },
  {
    abbr: "NR",
    code: "674",
    name: "Nauru",
  },
  {
    abbr: "NP",
    code: "977",
    name: "Nepal",
  },
  {
    abbr: "NL",
    code: "31",
    name: "Netherlands",
  },
  {
    abbr: "AN",
    code: "599",
    name: "Netherlands Antilles",
  },
  {
    abbr: "NC",
    code: "687",
    name: "New Caledonia",
  },
  {
    abbr: "NZ",
    code: "64",
    name: "New Zealand",
  },
  {
    abbr: "NI",
    code: "505",
    name: "Nicaragua",
  },
  {
    abbr: "NE",
    code: "227",
    name: "Niger",
  },
  {
    abbr: "NG",
    code: "234",
    name: "Nigeria",
  },
  {
    abbr: "NU",
    code: "683",
    name: "Niue",
  },
  {
    abbr: "NF",
    code: "672",
    name: "Norfolk Island",
  },
  {
    abbr: "MP",
    code: "1 670",
    name: "Northern Mariana Islands",
  },
  {
    abbr: "NO",
    code: "47",
    name: "Norway",
  },
  {
    abbr: "OM",
    code: "968",
    name: "Oman",
  },
  {
    abbr: "PK",
    code: "92",
    name: "Pakistan",
    mask: "(###) ###-####",
    placeholder: "(999) 999-9999",
  },
  {
    abbr: "PW",
    code: "680",
    name: "Palau",
  },
  {
    abbr: "PS",
    code: "970",
    name: "Palestinian Territory, Occupied",
  },
  {
    abbr: "PA",
    code: "507",
    name: "Panama",
  },
  {
    abbr: "PG",
    code: "675",
    name: "Papua New Guinea",
  },
  {
    abbr: "PY",
    code: "595",
    name: "Paraguay",
  },
  {
    abbr: "PE",
    code: "51",
    name: "Peru",
  },
  {
    abbr: "PH",
    code: "63",
    name: "Philippines",
  },
  {
    abbr: "PN",
    code: "872",
    name: "Pitcairn",
  },
  {
    abbr: "PL",
    code: "48",
    name: "Poland",
  },
  {
    abbr: "PT",
    code: "351",
    name: "Portugal",
  },
  {
    abbr: "PR",
    code: "1 939",
    name: "Puerto Rico",
  },
  {
    abbr: "QA",
    code: "974",
    name: "Qatar",
  },
  {
    abbr: "RO",
    code: "40",
    name: "Romania",
  },
  {
    abbr: "RU",
    code: "7",
    name: "Russia",
  },
  {
    abbr: "RW",
    code: "250",
    name: "Rwanda",
  },
  {
    abbr: "RE",
    code: "262",
    name: "Réunion",
  },
  {
    abbr: "BL",
    code: "590",
    name: "Saint Barthélemy",
  },
  {
    abbr: "SH",
    code: "290",
    name: "Saint Helena, Ascension and Tristan Da Cunha",
  },
  {
    abbr: "KN",
    code: "1 869",
    name: "Saint Kitts and Nevis",
  },
  {
    abbr: "LC",
    code: "1 758",
    name: "Saint Lucia",
  },
  {
    abbr: "MF",
    code: "590",
    name: "Saint Martin",
  },
  {
    abbr: "PM",
    code: "508",
    name: "Saint Pierre and Miquelon",
  },
  {
    abbr: "VC",
    code: "1 784",
    name: "Saint Vincent and the Grenadines",
  },
  {
    abbr: "WS",
    code: "685",
    name: "Samoa",
  },
  {
    abbr: "SM",
    code: "378",
    name: "San Marino",
  },
  {
    abbr: "ST",
    code: "239",
    name: "Sao Tome and Principe",
  },
  {
    abbr: "SA",
    code: "966",
    name: "Saudi Arabia",
    mask: "5-####-####",
    placeholder: "5-9999-9999",
  },
  {
    abbr: "SN",
    code: "221",
    name: "Senegal",
  },
  {
    abbr: "RS",
    code: "381",
    name: "Serbia",
  },
  {
    abbr: "SC",
    code: "248",
    name: "Seychelles",
  },
  {
    abbr: "SL",
    code: "232",
    name: "Sierra Leone",
  },
  {
    abbr: "SG",
    code: "65",
    name: "Singapore",
  },
  {
    abbr: "SK",
    code: "421",
    name: "Slovakia",
  },
  {
    abbr: "SI",
    code: "386",
    name: "Slovenia",
  },
  {
    abbr: "SB",
    code: "677",
    name: "Solomon Islands",
  },
  {
    abbr: "SO",
    code: "252",
    name: "Somalia",
  },
  {
    abbr: "ZA",
    code: "27",
    name: "South Africa",
  },
  {
    abbr: "GS",
    code: "500",
    name: "South Georgia and the South Sandwich Islands",
  },
  {
    abbr: "ES",
    code: "34",
    name: "Spain",
  },
  {
    abbr: "LK",
    code: "94",
    name: "Sri Lanka",
  },
  {
    abbr: "SD",
    code: "249",
    name: "Sudan",
  },
  {
    abbr: "SR",
    code: "597",
    name: "Suriname",
  },
  {
    abbr: "SJ",
    code: "47",
    name: "Svalbard and Jan Mayen",
  },
  {
    abbr: "SZ",
    code: "268",
    name: "Swaziland",
  },
  {
    abbr: "SE",
    code: "46",
    name: "Sweden",
  },
  {
    abbr: "CH",
    code: "41",
    name: "Switzerland",
  },
  {
    abbr: "SY",
    code: "963",
    name: "Syrian Arab Republic",
  },
  {
    abbr: "TW",
    code: "886",
    name: "Taiwan, Province of China",
  },
  {
    abbr: "TJ",
    code: "992",
    name: "Tajikistan",
  },
  {
    abbr: "TZ",
    code: "255",
    name: "Tanzania, United Republic of",
  },
  {
    abbr: "TH",
    code: "66",
    name: "Thailand",
  },
  {
    abbr: "TL",
    code: "670",
    name: "Timor-Leste",
  },
  {
    abbr: "TG",
    code: "228",
    name: "Togo",
  },
  {
    abbr: "TK",
    code: "690",
    name: "Tokelau",
  },
  {
    abbr: "TO",
    code: "676",
    name: "Tonga",
  },
  {
    abbr: "TT",
    code: "1 868",
    name: "Trinidad and Tobago",
  },
  {
    abbr: "TN",
    code: "216",
    name: "Tunisia",
  },
  {
    abbr: "TR",
    code: "90",
    name: "Turkey",
    mask: "(###) ###-####",
    placeholder: "(999) 999-9999",
  },
  {
    abbr: "TM",
    code: "993",
    name: "Turkmenistan",
  },
  {
    abbr: "TC",
    code: "1 649",
    name: "Turks and Caicos Islands",
  },
  {
    abbr: "TV",
    code: "688",
    name: "Tuvalu",
  },
  {
    abbr: "UG",
    code: "256",
    name: "Uganda",
  },
  {
    abbr: "UA",
    code: "380",
    name: "Ukraine",
  },
  {
    abbr: "AE",
    code: "971",
    name: "United Arab Emirates",
    mask: "5# ### ####",
    placeholder: "59 999 9999",
  },
  {
    abbr: "GB",
    code: "44",
    name: "United Kingdom",
    mask: "##-####-####",
    placeholder: "99-9999-9999",
  },
  {
    abbr: "US",
    code: "1",
    name: "United States of America",
    mask: "(###) ###-####",
    placeholder: "(999) 999-9999",
  },
  {
    abbr: "UY",
    code: "598",
    name: "Uruguay",
  },
  {
    abbr: "UZ",
    code: "998",
    name: "Uzbekistan",
  },
  {
    abbr: "VU",
    code: "678",
    name: "Vanuatu",
  },
  {
    abbr: "VE",
    code: "58",
    name: "Venezuela, Bolivarian Republic of",
  },
  {
    abbr: "VN",
    code: "84",
    name: "Viet Nam",
  },
  {
    abbr: "VG",
    code: "1 284",
    name: "Virgin Islands, British",
  },
  {
    abbr: "VI",
    code: "1 340",
    name: "Virgin Islands, U.S.",
  },
  {
    abbr: "WF",
    code: "681",
    name: "Wallis and Futuna",
  },
  {
    abbr: "YE",
    code: "967",
    name: "Yemen",
  },
  {
    abbr: "ZM",
    code: "260",
    name: "Zambia",
  },
  {
    abbr: "ZW",
    code: "263",
    name: "Zimbabwe",
  },
];
