




















import { bemBuilder } from "@/v2/util/bem-builder";
import { defineComponent, ref, computed } from "@vue/composition-api";
import {
  AtomButton,
  AtomButtonSizeEnum,
  AtomText,
  AtomTextTypeEnum,
  AtomTextColorEnum,
} from "@/v2/new-design-system";
import { useAcceptInviteState } from "./state";
import { userManagementMethodTrack } from "../../method";
import { acceptInvite } from "@/v2/repo/accept-invite";
import { generalErrorToast } from "@/v2/util/general-error-toast";
import RoleList from "../../components/RoleList.vue";
import { t } from "@/i18n";

const css = bemBuilder("accept-invite-access");

export default defineComponent({
  name: "AcceptInviteAccess",
  components: {
    AtomButton,
    AtomText,
    RoleList,
  },
  setup(_, context) {
    const {
      token,
      setIsInvalidInvite,
      inviteData,
      formData,
      switchStep,
      isTenantExist,
    } = useAcceptInviteState();

    const loadingButton = ref<boolean>(false);

    const labelButton = computed((): string => {
      return isTenantExist.value
        ? t("c.user_management.accept_invite.accept")
        : t("c.user_management.accept_invite.create_account");
    });

    async function submitForm() {
      loadingButton.value = true;

      const payload = {
        firstName: formData.value.firstName,
        lastName: formData.value.lastName,
        countryCode: formData.value.countryCode,
        phoneNumber: formData.value.phoneNumber,
        password: formData.value.password,
      };

      try {
        const res = await acceptInvite(
          token.value,
          isTenantExist.value,
          payload
        );

        userManagementMethodTrack(context, "User invite completed", {
          roles: [inviteData.value.role.toLowerCase()],
        });

        if (isTenantExist.value) {
          context.root.$router.push({
            name: "auth.login",
            query: {
              email: inviteData.value.email,
            },
          });
          return;
        }

        context.root.$router.push({
          name: "auth.account-setup",
          params: {
            // @ts-ignore
            hash: res.hash,
          },
        });
      } catch (e) {
        const msgError = t("c.user_management.accept_invite.error_toast_desc");

        if (isTenantExist.value) {
          setIsInvalidInvite(true);
          return;
        }

        switchStep();
        generalErrorToast(msgError);
      } finally {
        loadingButton.value = false;
      }
    }

    return {
      t,
      css,
      AtomTextTypeEnum,
      AtomTextColorEnum,
      AtomButtonSizeEnum,
      loadingButton,
      labelButton,
      submitForm,
      inviteData,
      isTenantExist,
    };
  },
});
