import { ref, computed } from "@vue/composition-api";
import { UserManagementTenantRoleEnum } from "@/v2/enum/user-management";

const firstStep = ref<boolean>(true);
const token = ref<string>("");
const isInvalidInvite = ref<boolean>(false);
const isTenantExist = ref<boolean>(false);

const initialFormData = ref<IFormData>({
  firstName: "",
  lastName: "",
  countryCode: "971",
  phoneNumber: "",
  password: "",
  confirmPassword: "",
});

const formData = ref<IFormData>(initialFormData.value);

const initialInviteData = ref<IInviteData>({
  email: "",
  role: null,
  invitee: {
    firstName: "",
    lastName: "",
  },
  businesses: [],
});

const inviteData = ref<IInviteData>(initialInviteData.value);

export function useAcceptInviteState(): any {
  function setToken(tokenCode: string): void {
    token.value = tokenCode;
  }

  function setIsInvalidInvite(status: boolean): void {
    isInvalidInvite.value = status;
  }

  function setInviteData(inviteObj: IInviteData): void {
    inviteData.value = inviteObj;
  }

  function switchStep(): void {
    firstStep.value = !firstStep.value;
  }

  function setFormData(item: string, value: string): void {
    // @ts-ignore
    formData.value[item] = value;
  }

  function setIsTenantExist(status: boolean): void {
    isTenantExist.value = status;

    if (isTenantExist.value) {
      firstStep.value = false;
    }
  }

  return {
    firstStep: computed((): boolean => firstStep.value),
    token: computed((): string => token.value),
    setToken,
    isInvalidInvite: computed((): boolean => isInvalidInvite.value),
    setIsInvalidInvite,
    inviteData: computed((): IInviteData => inviteData.value),
    setInviteData,
    switchStep,
    formData: computed((): IFormData => formData.value),
    setFormData,
    isTenantExist: computed((): boolean => isTenantExist.value),
    setIsTenantExist,
  };
}

interface IFormData {
  firstName: string;
  lastName: string;
  countryCode: string;
  phoneNumber: string;
  password: string;
  confirmPassword: string;
}

interface IInviteData {
  email: string;
  role: UserManagementTenantRoleEnum | null;
  invitee: {
    firstName: string;
    lastName: string;
  };
  businesses: Array<IBusiness>;
}

interface IBusiness {
  name: string;
  logo: string;
  outlets: Array<string>;
}
