import { ref, computed } from "@vue/composition-api";
import { UserManagementTenantRoleEnum } from "@/v2/enum/user-management";

const role = ref<string>("");

const accessType = ref<string>("");

const accessIds = ref<Array<string>>([]);

const canProceed = ref<boolean>(false);

export function useUserRoleState(): any {
  // Set Role
  function setRole(roleItem: UserManagementTenantRoleEnum): void {
    role.value = roleItem;
  }

  // Clear Role
  function clearRole(): void {
    role.value = "";
  }

  // Is Administrator Role
  const isAdministratorRole = computed((): boolean => {
    return role.value === UserManagementTenantRoleEnum.ADMINISTRATOR;
  });

  // Set AccessType
  function setAccessType(type: string): void {
    accessType.value = type;
  }

  // Clear AccessType
  function clearAccessType(): void {
    accessType.value = "";
  }

  // Set AccessIds
  function setAccessIds(ids: Array<string>): void {
    accessIds.value = ids;
  }

  // Clear AccessIds
  function clearAccessIds(): void {
    accessIds.value = [];
  }

  function setCanProceed(status: boolean): void {
    canProceed.value = status;
  }

  return {
    // Role
    role: computed((): string => role.value),
    setRole,
    clearRole,
    isAdministratorRole,

    // AccessType
    accessType: computed((): string => accessType.value),
    setAccessType,
    clearAccessType,

    // AccessIds
    accessIds: computed((): Array<string> => accessIds.value),
    setAccessIds,
    clearAccessIds,

    // CanProceed
    canProceed: computed((): boolean => canProceed.value),
    setCanProceed,
  };
}
