











































import { bemBuilder } from "@/v2/util/bem-builder";
import { defineComponent, PropType, computed } from "@vue/composition-api";
import { IUserManagementListingItemProps } from "./props";
import {
  AtomText,
  AtomTextTypeEnum,
  AtomTextColorEnum,
} from "@/v2/new-design-system";
import UserManagementListingItemDelete from "./ListingItemDelete.vue";
import {
  UserManagementUserTypeEnum,
  UserManagementTenantRoleEnum,
  UserManagementUserAccessTypeEnum,
} from "@/v2/enum/user-management";
import { t, tc } from "@/i18n";

const css = bemBuilder("user-management-listing-item");

export default defineComponent<IUserManagementListingItemProps>({
  name: "UserManagementListingItem",
  components: {
    AtomText,
    UserManagementListingItemDelete,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    fullName: {
      type: String,
      default: "",
    },
    email: {
      type: String,
      required: true,
    },
    role: {
      type: String as PropType<UserManagementTenantRoleEnum>,
      required: true,
    },
    accessType: {
      type: String as PropType<UserManagementUserAccessTypeEnum>,
      required: true,
    },
    accessIds: {
      type: Array,
      default: () => [],
    },
    isInvite: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const isAccountOwner = computed((): boolean => {
      return props.role === UserManagementTenantRoleEnum.ACCOUNT_OWNER;
    });

    const isAdministrator = computed((): boolean => {
      return props.role === UserManagementTenantRoleEnum.ADMINISTRATOR;
    });

    const isManager = computed((): boolean => {
      return props.role === UserManagementTenantRoleEnum.MANAGER;
    });

    const isStaff = computed((): boolean => {
      return props.role === UserManagementTenantRoleEnum.STAFF;
    });

    const isFinance = computed((): boolean => {
      return props.role === UserManagementTenantRoleEnum.FINANCE;
    });

    const isMarketing = computed((): boolean => {
      return props.role === UserManagementTenantRoleEnum.MARKETING;
    });

    const isWaiter = computed((): boolean => {
      return props.role === UserManagementTenantRoleEnum.WAITER;
    });

    const isHostess = computed((): boolean => {
      return props.role === UserManagementTenantRoleEnum.HOSTESS;
    });

    const isOwnUser = computed((): boolean => {
      const authId = context.root.$store.getters["auth/getId"];
      return authId === props.id;
    });

    const canDelete = computed((): boolean => {
      return !isAccountOwner.value && !isOwnUser.value;
    });

    const redirectUser = computed(() => {
      if (isOwnUser.value) {
        return {
          name: "user-management.profile",
          params: {
            businessId: props.businessId,
          },
        };
      }

      return {
        name: "user-management.edit",
        params: {
          businessId: props.businessId,
          userType: userType.value,
          userId: props.id,
        },
      };
    });

    const userType = computed((): string => {
      return props.isInvite
        ? UserManagementUserTypeEnum.INVITE
        : UserManagementUserTypeEnum.TENANT;
    });

    const labelRole = computed((): string => {
      const map: Record<string, string> = {
        [UserManagementTenantRoleEnum.ACCOUNT_OWNER]: t(
          "c.user_management.roles.account_owner"
        ),
        [UserManagementTenantRoleEnum.ADMINISTRATOR]: t(
          "c.user_management.roles.administrator"
        ),
        [UserManagementTenantRoleEnum.FINANCE]: t(
          "c.user_management.roles.finance"
        ),
        [UserManagementTenantRoleEnum.MANAGER]: t(
          "c.user_management.roles.manager"
        ),
        [UserManagementTenantRoleEnum.MARKETING]: t(
          "c.user_management.roles.marketing"
        ),
        [UserManagementTenantRoleEnum.STAFF]: t(
          "c.user_management.roles.staff"
        ),
        [UserManagementTenantRoleEnum.SUPER_ADMIN]: t(
          "c.user_management.roles.super_admin"
        ),
        [UserManagementTenantRoleEnum.WAITER]: t(
          "c.user_management.roles.waiter"
        ),
        [UserManagementTenantRoleEnum.HOSTESS]: t(
          "c.user_management.roles.hostess"
        ),
      };

      return map[props.role];
    });

    const accessTypeOrganization = computed((): boolean => {
      return props.accessType === UserManagementUserAccessTypeEnum.ORGANIZATION;
    });

    const labelAccesses = computed((): string => {
      if (
        accessTypeOrganization.value &&
        (isStaff.value ||
          isManager.value ||
          isWaiter.value ||
          isHostess.value ||
          isFinance.value ||
          isMarketing.value)
      ) {
        return t("label.all_outlets");
      }

      if (isAccountOwner.value) {
        return t("label.all_access");
      }

      if (accessTypeOrganization.value && isAdministrator.value) {
        return t("label.all_businesses");
      }

      if (!accessTypeOrganization.value && isAdministrator.value) {
        return tc("label.x_businesses", props.accessIds.length);
      }

      return tc("label.x_outlets", props.accessIds.length);
    });

    return {
      css,
      t,
      AtomTextTypeEnum,
      AtomTextColorEnum,
      labelRole,
      labelAccesses,
      isAccountOwner,
      canDelete,
      redirectUser,
    };
  },
});
