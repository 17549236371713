



















import { bemBuilder } from "@/v2/util/bem-builder";
import { defineComponent } from "@vue/composition-api";
import { AtomText, AtomTextTypeEnum, AtomCard } from "@/v2/new-design-system";
import { useTenantProfileState } from "./state";
import RoleList from "../components/RoleList.vue";
import { t } from "@/i18n";

const css = bemBuilder("user-management-profile-role");

export default defineComponent({
  name: "UserManagementProfileRole",
  components: {
    AtomText,
    AtomCard,
    RoleList,
  },
  setup() {
    const { profileData } = useTenantProfileState();

    return {
      t,
      css,
      profileData,
      AtomTextTypeEnum,
    };
  },
});
