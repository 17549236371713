

















































































import { bemBuilder } from "@/v2/util/bem-builder";
import {
  defineComponent,
  ref,
  computed,
  onMounted,
  watch,
} from "@vue/composition-api";
import {
  AtomIcon,
  AtomLoading,
  AtomText,
  AtomTextTypeEnum,
  AtomTextColorEnum,
  MolPagination,
} from "@/v2/new-design-system";
import UserManagementListingItem from "./ListingItem.vue";
import { UserManagementTenantsSortOrderEnum } from "@/v2/enum/user-management";
import { useUserManagementState } from "../state";
import {
  userManagementMethodGetInvites,
  userManagementMethodGetTenants,
} from "../method";
import { t, tc } from "@/i18n";

const css = bemBuilder("user-management-listing");

export default defineComponent({
  name: "UserManagementListing",
  components: {
    AtomText,
    AtomIcon,
    AtomLoading,
    MolPagination,
    UserManagementListingItem,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
  },
  setup() {
    const {
      organization,
      setCanSeeUsers,
      invites,
      tenants,
      tenantsOrderBy,
      setTenantsOrderBy,
      tenantsPaginator,
      showPaginator,
      setTenantsPaginatorCurrentPage,
      setTenantsPaginatorDefault,
      totalUsers,
      setTotalUsers,
    } = useUserManagementState();

    const isLoading = ref<boolean>(false);
    const sortByNameAsc = ref<boolean>(true);

    const iconSortColumn = computed((): string => {
      return `sort-${sortByNameAsc.value ? "asc" : "desc"}`;
    });

    const orderedInvitates = computed(() => {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return invites.value.sort((objInviteA: any, objInviteB: any) =>
        objInviteA.email.localeCompare(objInviteB.email)
      );
    });

    onMounted(() => {
      getUsers();
    });

    async function changePage(currentPage: number) {
      setTenantsPaginatorCurrentPage(currentPage);
      await getUsers();
    }

    watch(
      () => organization.value,
      async () => {
        setTenantsPaginatorDefault();
        await getUsers();
      }
    );

    async function getUsers() {
      isLoading.value = true;

      try {
        await Promise.all([
          userManagementMethodGetInvites(organization.value.id),
          userManagementMethodGetTenants(
            organization.value.id,
            tenantsOrderBy.value,
            tenantsPaginator.value.currentPage,
            tenantsPaginator.value.perPage
          ),
        ]);
        setTotalUsers(invites.value.length + tenantsPaginator.value.total);
      } catch (e) {
        setCanSeeUsers(false);
      } finally {
        isLoading.value = false;
      }
    }

    async function toggleSortColumnName() {
      sortByNameAsc.value = !sortByNameAsc.value;

      setTenantsOrderBy(
        sortByNameAsc.value
          ? UserManagementTenantsSortOrderEnum.ASC
          : UserManagementTenantsSortOrderEnum.DESC
      );
      setTenantsPaginatorDefault();
      await getUsers();
    }

    return {
      css,
      t,
      tc,
      AtomTextTypeEnum,
      AtomTextColorEnum,
      isLoading,
      totalUsers,
      iconSortColumn,
      orderedInvitates,
      invites,
      tenants,
      changePage,
      toggleSortColumnName,
      tenantsPaginator,
      showPaginator,
    };
  },
});
