import { FetchResult } from "@apollo/client";

export function graphqlExtractGraphqlErrors(
  response: FetchResult<any, Record<string, any>, Record<string, any>>
): Array<string> | null {
  const errorArray: Array<string> = [];

  if (Array.isArray(response.errors)) {
    response.errors.forEach((error) => {
      if (error.extensions?.category === "graphql") {
        errorArray.push(error.message);
      }
    });
  }

  if (errorArray.length) return errorArray;

  return null;
}
