import { graphql } from "../graphql";
import mutation from "./mutation.gql";
import { FetchResult } from "@apollo/client";
import { graphqlExtractGraphqlErrors } from "@/v2/util/graphql-extract-graphql-errors";
import { IRepoErrors } from "../errors";
import { graphqlRemapValidationErrors } from "@/v2/util/graphql-remap-validation-errors";
import {
  UserManagementTenantRoleEnum,
  UserManagementUserAccessTypeEnum,
} from "@/v2/enum/user-management";

export interface IUpdateInviteRequest {
  id: string;
  organizationId: string;
  email: string;
  role: UserManagementTenantRoleEnum;
  accessType: UserManagementUserAccessTypeEnum;
  accessIds: Array<string>;
}

export async function updateInvite(
  request: IUpdateInviteRequest
): Promise<boolean> {
  let response: FetchResult<any, Record<string, any>, Record<string, any>>;
  const errors: IRepoErrors<ValidationErrors> = {};

  try {
    response = await graphql.mutate({
      mutation,
      variables: {
        inviteId: request.id,
        organizationId: request.organizationId,
        email: request.email,
        role: request.role,
        accessType: request.accessType,
        accessIds: request.accessIds,
      },
    });
  } catch (error) {
    errors.networkError = true;
    throw new UpdateInviteError(errors);
  }

  if (response.errors) {
    errors.graphqlError = graphqlExtractGraphqlErrors(response);
    errors.validationError = graphqlRemapValidationErrors(response);

    throw new UpdateInviteError(errors);
  }

  return response.data.editInvite;
}

export class UpdateInviteError extends Error {
  errors: IRepoErrors<ValidationErrors>;

  constructor(errors: IRepoErrors<ValidationErrors>) {
    super("Error in repo: updateInviteError");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}

type ValidationErrors = {};
