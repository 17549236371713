import { ref, computed } from "@vue/composition-api";
import {
  IUserManagementListingProps,
  IUserManagementListingItemProps,
  IUserManagementListingPaginatorProps,
  IUserManagementListingOrderByProps,
} from "./listing/props";
import {
  UserManagementUserAccessTypeEnum,
  UserManagementTenantRoleEnum,
  UserManagementTenantsSortOrderEnum,
  UserManagementTenantsOrderByColumnEnum,
} from "@/v2/enum/user-management";

// Organizations state
const organizations = ref<Array<IOrganizationItem>>([]);

// Organization default state
const organizationDefault = ref<IOrganizationItem>({
  name: "",
  id: "",
  businesses: [],
});

// Organization state
const organization = ref<IOrganizationItem>(organizationDefault.value);

const canSeeUsers = ref<boolean>(true);

// Invites state
const invites = ref<IUserManagementListingProps["invites"]>([]);

// Tenants state
const tenants = ref<IUserManagementListingProps["tenants"]["list"]>([]);

// Tenants orderBy default state
const tenantsOrderByDefault = ref<IUserManagementListingOrderByProps>({
  column: UserManagementTenantsOrderByColumnEnum.FNAME,
  order: UserManagementTenantsSortOrderEnum.ASC,
});

// Tenants orderBy state
const tenantsOrderBy = ref<IUserManagementListingOrderByProps>(
  tenantsOrderByDefault.value
);

// Tenants paginator default state
const paginatorDefault = ref<IUserManagementListingPaginatorProps>({
  count: 5,
  currentPage: 1,
  lastPage: 0,
  perPage: 15,
  total: 0,
});

// Tenants paginator state
const tenantsPaginator = ref<IUserManagementListingPaginatorProps>(
  paginatorDefault.value
);

const totalUsers = ref<number>(0);

// User default state
const userDefault = ref<IUser>({
  id: "",
  fullName: "",
  email: "",
  phone: "",
  role: null,
  accessibleResources: {
    accessType: null,
    accessIds: [],
  },
});

// User default state
const user = ref<IUser>(userDefault.value);

export function useUserManagementState(): any {
  // Set Organizations
  function setOrganizations(organizationsList: Array<IOrganizationItem>): void {
    organizations.value = organizationsList;
  }

  // Set Organization state
  function setOrganization(organizationItem: IOrganizationItem): void {
    organization.value = organizationItem;
  }

  // Set can see Users
  function setCanSeeUsers(status: boolean): void {
    canSeeUsers.value = status;
  }

  // Set Invites
  function setInvites(
    invitesList: IUserManagementListingProps["invites"]
  ): void {
    invites.value = invitesList;
  }

  // Delete Invite
  function deleteInvite(inviteId: string): void {
    invites.value = invites.value.filter(
      (obj: IUserManagementListingItemProps) => obj.id !== inviteId
    );

    setTotalUsers(totalUsers.value - 1);
  }

  // Set Tenants
  function setTenants(
    tenantsList: IUserManagementListingProps["tenants"]["list"]
  ): void {
    tenants.value = tenantsList;
  }

  // Delete Tenant
  function deleteTenant(tenantId: string): void {
    tenants.value = tenants.value.filter(
      (obj: IUserManagementListingItemProps) => obj.id !== tenantId
    );

    setTotalUsers(totalUsers.value - 1);
  }

  // Set Tenants order by
  function setTenantsOrderBy(
    sortOrder: UserManagementTenantsSortOrderEnum
  ): void {
    tenantsOrderBy.value = {
      ...tenantsOrderBy.value,
      order: sortOrder,
    };
  }

  // Set Tenants paginator
  function setTenantsPaginator(
    paginator: IUserManagementListingPaginatorProps
  ): void {
    tenantsPaginator.value = paginator;
  }

  // Set Tenants paginator current page
  function setTenantsPaginatorCurrentPage(currentPage: number): void {
    tenantsPaginator.value = {
      ...tenantsPaginator.value,
      currentPage: currentPage,
    };
  }

  // Set Tenants paginator default
  function setTenantsPaginatorDefault(): void {
    tenantsPaginator.value = paginatorDefault.value;
  }

  // Set User
  function setUser(userObj: IUser): void {
    user.value = userObj;
  }

  // Clear User
  function clearUser(): void {
    user.value = userDefault.value;
  }

  // Set Total Users
  function setTotalUsers(number: number): void {
    totalUsers.value = number;
  }

  return {
    // Organizations
    organizations: computed(
      (): Array<IOrganizationItem> => organizations.value
    ),
    setOrganizations,

    // Organization
    organization: computed((): IOrganizationItem => organization.value),
    setOrganization,

    // Cannot see users
    canSeeUsers: computed((): boolean => canSeeUsers.value),
    setCanSeeUsers,

    // Invites
    invites: computed(
      (): IUserManagementListingProps["invites"] => invites.value
    ),
    setInvites,
    deleteInvite,

    // Tenants
    tenants: computed(
      (): IUserManagementListingProps["tenants"]["list"] => tenants.value
    ),
    setTenants,
    deleteTenant,

    tenantsOrderBy: computed(
      (): IUserManagementListingOrderByProps => tenantsOrderBy.value
    ),
    setTenantsOrderBy,
    tenantsPaginator: computed(
      (): IUserManagementListingPaginatorProps => tenantsPaginator.value
    ),
    showPaginator: computed((): boolean => tenantsPaginator.value.total > 15),
    setTenantsPaginator,
    setTenantsPaginatorCurrentPage,
    setTenantsPaginatorDefault,

    // Total Users
    totalUsers: computed((): number => totalUsers.value),
    setTotalUsers,

    // User
    user: computed((): IUser => user.value),
    setUser,
    clearUser,
  };
}

// Interface Organization
export interface IOrganizationItem {
  id: string;
  name: string;
  businesses: Array<IOrganizationItemBusiness>;
}

export interface IOrganizationItemBusiness {
  id: string;
  name: string;
  outlets: Array<IOrganizationItemBusinessOutlets>;
}

export interface IOrganizationItemBusinessOutlets {
  id: string;
  name: string;
}

// Interface User
export interface IUser {
  id: string;
  fullName: string;
  email: string;
  phone?: string;
  role: UserManagementTenantRoleEnum | null;
  accessibleResources: IAccessibleResources;
}

interface IAccessibleResources {
  accessType: UserManagementUserAccessTypeEnum | null;
  accessIds: Array<string>;
}
