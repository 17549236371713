



























































































import { bemBuilder } from "@/v2/util/bem-builder";
import {
  defineComponent,
  ref,
  PropType,
  computed,
  onMounted,
} from "@vue/composition-api";
import {
  AtomButton,
  AtomButtonSizeEnum,
  AtomIcon,
  AtomInput,
  AtomText,
  AtomTextTypeEnum,
  AtomTextColorEnum,
  AtomLoading,
  MolBackNav,
  MolFormControl,
} from "@/v2/new-design-system";
import UserRole from "../components/user-role/UserRole.vue";
import EditDelete from "./EditDelete.vue";
import { Toast } from "@/design-system";
import {
  userManagementMethodLoadOrganization,
  userManagementMethodFetchUser,
  userManagementMethodUpdateUser,
} from "../method";
import { useUserManagementState } from "../state";
import { useUserRoleState } from "../components/user-role/state";
import { IUserManagementEditProps } from "./props";
import {
  UserManagementTenantRoleEnum,
  UserManagementUserAccessTypeEnum,
  UserManagementUserTypeEnum,
} from "@/v2/enum/user-management";
import { isValidEmail } from "@/v2/util/validator/email";
import { generalErrorToast } from "@/v2/util/general-error-toast";
import { t } from "@/i18n";

const css = bemBuilder("user-management-edit");

export default defineComponent<IUserManagementEditProps>({
  name: "UserManagementEdit",
  components: {
    AtomButton,
    AtomIcon,
    AtomInput,
    AtomText,
    EditDelete,
    AtomLoading,
    MolBackNav,
    MolFormControl,
    UserRole,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
    userType: {
      type: String as PropType<UserManagementUserTypeEnum>,
      required: true,
    },
    userId: {
      type: String,
      required: true,
    },
  },
  setup(props, context) {
    const {
      role,
      setRole,
      clearRole,
      accessType,
      setAccessType,
      accessIds,
      setAccessIds,
      clearAccessIds,
      clearAccessType,
      canProceed,
    } = useUserRoleState();

    const { organization, user, clearUser } = useUserManagementState();

    const isLoading = ref<boolean>(true);
    const isLoadingButton = ref<boolean>(false);
    const email = ref<string>("");

    const isInvite = computed((): boolean => {
      return props.userType === UserManagementUserTypeEnum.INVITE;
    });

    const authId = computed((): string => {
      return context.root.$store.getters["auth/getId"];
    });

    const isAccountOwner = computed((): boolean => {
      return role.value === UserManagementTenantRoleEnum.ACCOUNT_OWNER;
    });

    const invalidEmailMsg = computed((): string => {
      return !isValidEmail(email.value)
        ? t("c.user_management.edit_page.email_invalid")
        : "";
    });

    const canSaveChanges = computed((): boolean => {
      return (
        Boolean(role?.value) &&
        Boolean(email.value) &&
        isValidEmail(email.value) &&
        canProceed.value
      );
    });

    onMounted(async () => {
      Promise.all([
        clearUser(),
        clearRole(),
        clearAccessIds(),
        clearAccessType(),
        await userManagementMethodLoadOrganization(props.businessId),
        getUser(),
      ]);
    });

    async function getUser(): Promise<void> {
      isLoading.value = true;

      try {
        await userManagementMethodFetchUser(
          props.userId,
          organization.value.id,
          isInvite.value
        );
        setRole(user.value.role);
        defineAccess();
        email.value = user.value.email;
      } catch (e) {
        generalErrorToast();
      } finally {
        isLoading.value = false;
      }
    }

    function defineAccess() {
      const isOrganizationAccess =
        user.value.accessibleResources.accessType ===
        UserManagementUserAccessTypeEnum.ORGANIZATION;

      setAccessType(user.value.accessibleResources.accessType);
      setAccessIds(
        isOrganizationAccess ? [] : user.value.accessibleResources.accessIds
      );
    }

    async function submitForm() {
      if (!canSaveChanges.value) return;

      isLoadingButton.value = true;

      const request = {
        id: user.value.id,
        organizationId: organization.value.id,
        email: email.value,
        role: role.value,
        accessType: accessType.value,
        accessIds: accessIds.value,
      };

      try {
        await userManagementMethodUpdateUser(request, isInvite.value);

        context.root.$router.push({
          name: "user-management.index",
          params: {
            businessId: props.businessId,
          },
        });

        new Toast().create({
          type: "success",
          title: t("c.user_management.edit_page.success_toast_title"),
          text: isInvite.value
            ? t("c.user_management.edit_page.success_toast_desc_invite")
            : t("c.user_management.edit_page.success_toast_desc"),
        });
      } catch (e) {
        generalErrorToast();
      } finally {
        isLoadingButton.value = false;
      }
    }

    return {
      css,
      t,
      AtomTextTypeEnum,
      AtomTextColorEnum,
      AtomButtonSizeEnum,
      user,
      authId,
      isLoading,
      isLoadingButton,
      isAccountOwner,
      canSaveChanges,
      submitForm,
      isInvite,
      email,
      accessIds,
      accessType,
      isValidEmail,
      invalidEmailMsg,
    };
  },
});
