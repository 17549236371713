import { graphql } from "../graphql";
import query from "./query.gql";
import { IRepoErrors } from "../errors";
import { FetchResult } from "@apollo/client";
import { graphqlExtractGraphqlErrors } from "@/v2/util/graphql-extract-graphql-errors";
import { graphqlRemapValidationErrors } from "@/v2/util/graphql-remap-validation-errors";
import {
  UserManagementTenantRoleEnum,
  UserManagementUserAccessTypeEnum,
  UserManagementTenantsOrderByColumnEnum,
  UserManagementTenantsSortOrderEnum,
} from "@/v2/enum/user-management";

export async function fetchTenants(
  organizationId: string,
  orderBy: IOrderBy,
  page: number,
  first: number
): Promise<IFetchTenantsResponse> {
  let response: FetchResult<any, Record<string, any>, Record<string, any>>;
  const errors: IRepoErrors<ValidationErrors> = {};

  try {
    response = await graphql.query({
      query,
      variables: {
        organizationId,
        orderBy: { value: orderBy },
        page,
        first,
      },
    });
  } catch (error) {
    errors.networkError = true;
    throw new FetchTenantsError(errors);
  }

  if (response.errors) {
    errors.graphqlError = graphqlExtractGraphqlErrors(response);
    errors.validationError = graphqlRemapValidationErrors(response);
    throw new FetchTenantsError(errors);
  }

  return {
    list: response.data.tenants.data,
    paginator: response.data.tenants.paginatorInfo,
  };
}

export class FetchTenantsError extends Error {
  errors: IRepoErrors<ValidationErrors>;

  constructor(errors: IRepoErrors<ValidationErrors>) {
    super("Error in repo: FetchTenants");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}

type ValidationErrors = {};

interface IFetchTenantsResponse {
  list: Array<IFetchTenantsResponseItem>;
  paginator: IFetchTenantsResponsePaginator;
}

interface IFetchTenantsResponseItem {
  id: string;
  fullName?: string;
  email: string;
  role: UserManagementTenantRoleEnum;
  accessibleResources: IAccessibleResources;
}

interface IAccessibleResources {
  accessType: UserManagementUserAccessTypeEnum;
  accessIds: Array<string>;
}

interface IFetchTenantsResponsePaginator {
  count: number;
  currentPage: number;
  lastPage: number;
  perPage: number;
  total: number;
}

interface IOrderBy {
  column: UserManagementTenantsOrderByColumnEnum;
  order: UserManagementTenantsSortOrderEnum;
}
