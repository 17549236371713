export function userManagementMethodTrack(
  context: any,
  eventName: string,
  additionalData: Record<string, any> = {}
): void {
  // @ts-ignore
  context.root.$analytics.trackEvent(eventName, {
    event_category: "user_management",
    ...additionalData,
  });
}
