





















import { bemBuilder } from "@/v2/util/bem-builder";
import { defineComponent } from "@vue/composition-api";
import { AtomText } from "@/v2/new-design-system";
import { AtomTextTypeEnum } from "@/v2/new-design-system/atom/text/type.enum";
import { AtomTextColorEnum } from "@/v2/new-design-system/atom/text/color.enum";
import { useTenantProfileState } from "./state";
import { t } from "@/i18n";

const css = bemBuilder("user-management-profile-email");

export default defineComponent({
  name: "UserManagementProfileEmail",
  components: {
    AtomText,
  },
  setup() {
    const { profileData } = useTenantProfileState();

    return {
      css,
      t,
      AtomTextTypeEnum,
      AtomTextColorEnum,
      profileData,
    };
  },
});
