
















































































































import { bemBuilder } from "@/v2/util/bem-builder";
import { defineComponent, ref, computed } from "@vue/composition-api";
import {
  AtomButton,
  AtomText,
  AtomSelect,
  MolFormControl,
  MolInputPassword,
  OrgFormInput,
} from "@/v2/new-design-system";
import { AtomTextTypeEnum } from "@/v2/new-design-system/atom/text/type.enum";
import { AtomTextColorEnum } from "@/v2/new-design-system/atom/text/color.enum";
import { AtomButtonSizeEnum } from "@/v2/new-design-system/atom/button/size.enum";
// @ts-ignore
import countries from "@/utils/enums/countries";
import { useAcceptInviteState } from "./state";
import { t } from "@/i18n";

const css = bemBuilder("accept-invite-form");

export default defineComponent({
  name: "AcceptInviteForm",
  components: {
    AtomButton,
    AtomText,
    AtomSelect,
    MolFormControl,
    MolInputPassword,
    OrgFormInput,
  },
  setup() {
    const { inviteData, formData, switchStep, setFormData } =
      useAcceptInviteState();

    const msgPasswordMatch = ref<string>("");

    const inviteeName = computed((): string => {
      return `${inviteData.value.invitee.firstName} ${inviteData.value.invitee.lastName}`;
    });

    const codeList = computed(() => {
      const codes = countries.map((obj: any) => obj.code);

      function removeDuplicates(codes: Array<string>) {
        let unique: any = {};
        codes.forEach((index) => {
          if (!unique[index]) {
            unique[index] = true;
          }
        });
        return Object.keys(unique);
      }

      const uniqueCodes = removeDuplicates(codes);
      uniqueCodes.sort((a, b) => a.localeCompare(b, "en"));

      return uniqueCodes.filter((code) => {
        return code !== "";
      });
    });

    const canGoToNextStep = computed((): boolean => {
      return Boolean(
        formData.value.firstName &&
          formData.value.lastName &&
          formData.value.countryCode &&
          formData.value.phoneNumber.length > 4 &&
          formData.value.password &&
          formData.value.confirmPassword
      );
    });

    function nextStep() {
      if (
        formData.value.password &&
        formData.value.password !== formData.value.confirmPassword
      ) {
        msgPasswordMatch.value = t(
          "c.user_management.accept_invite.password_dont_match"
        );
        return;
      }

      msgPasswordMatch.value = "";
      switchStep();
    }

    return {
      t,
      css,
      AtomTextTypeEnum,
      AtomTextColorEnum,
      AtomButtonSizeEnum,
      canGoToNextStep,
      nextStep,
      inviteeName,
      formData,
      inviteData,
      msgPasswordMatch,
      codeList,
      setFormData,
    };
  },
});
