export enum UserManagementUserAccessEnum {
  ALL_ACCESS = "ALL_ACCESS",
  SELECTED_ACCESS = "SELECTED_ACCESS",
}

export enum UserManagementUserAccessTypeEnum {
  BUSINESS = "BUSINESS",
  OUTLET = "OUTLET",
  ORGANIZATION = "ORGANIZATION",
}
