import { fetchInvite } from "@/v2/repo/fetch-invite";
import { fetchTenant } from "@/v2/repo/fetch-tenant";
import { useUserManagementState } from "../state";

export async function userManagementMethodFetchUser(
  id: string,
  organizationId: string,
  isInvite: boolean
): Promise<void> {
  const { setUser } = useUserManagementState();
  const request = { id, organizationId };
  try {
    const userData = isInvite
      ? await fetchInvite(request)
      : await fetchTenant(request);
    setUser(userData);
  } catch (error: any) {
    throw new Error(error);
  }
}
