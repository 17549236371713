



























import { bemBuilder } from "@/v2/util/bem-builder";
import { defineComponent, ref, onMounted } from "@vue/composition-api";
import { AtomText, MolLoadingZone } from "@/v2/new-design-system";
import { AtomTextTypeEnum } from "@/v2/new-design-system/atom/text/type.enum";
import { AtomTextColorEnum } from "@/v2/new-design-system/atom/text/color.enum";
import {
  UserManagementProfilePersonalInfo,
  UserManagementProfileEmail,
  UserManagementProfileSecurity,
  UserManagementProfileRole,
} from "./";
import { useTenantProfileState } from "./state";
import { fetchTenantProfile } from "@/v2/repo/fetch-tenant-profile";
import { generalErrorToast } from "@/v2/util/general-error-toast";
import { t } from "@/i18n";

const css = bemBuilder("user-management-profile");

export default defineComponent({
  name: "UserManagementProfile",
  components: {
    AtomText,
    MolLoadingZone,
    UserManagementProfilePersonalInfo,
    UserManagementProfileEmail,
    UserManagementProfileSecurity,
    UserManagementProfileRole,
  },
  setup() {
    const isLoading = ref<boolean>(false);

    const { setProfileData } = useTenantProfileState();

    onMounted(() => {
      getUser();
    });

    async function getUser(): Promise<void> {
      isLoading.value = true;
      try {
        const res = await fetchTenantProfile();
        setProfileData(res);
      } catch (e) {
        generalErrorToast();
      } finally {
        isLoading.value = false;
      }
    }

    return {
      css,
      t,
      AtomTextTypeEnum,
      AtomTextColorEnum,
      isLoading,
    };
  },
});
