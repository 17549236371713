import { ref, computed } from "@vue/composition-api";
import { UserManagementTenantRoleEnum } from "@/v2/enum/user-management";

const initialProfileData = ref<IProfileData>({
  firstName: "",
  lastName: "",
  fullName: "",
  email: "",
  phone: "",
  organizations: [],
});

const profileData = ref<IProfileData>(initialProfileData.value);

export function useTenantProfileState(): any {
  function setProfileData(profile: IProfileData): void {
    profileData.value = profile;
  }

  return {
    profileData: computed((): IProfileData => profileData.value),
    setProfileData,
  };
}

interface IProfileData {
  firstName: string;
  lastName: string;
  fullName: string;
  email: string;
  phone: string;
  organizations: Array<AccessibleOrganization>;
}

interface AccessibleOrganization {
  name: string;
  role: UserManagementTenantRoleEnum;
  businesses: Array<AccessibleBusiness>;
}

interface AccessibleBusiness {
  name: string;
  logo: string | null;
  outlets: Array<string>;
}
