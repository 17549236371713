




































import { bemBuilder } from "@/v2/util/bem-builder";
import { defineComponent, ref } from "@vue/composition-api";
import {
  AtomText,
  AtomTextTypeEnum,
  AtomTextColorEnum,
  AtomRadio,
} from "@/v2/new-design-system";
import UserRoleAccess from "./UserRoleAccess.vue";
import { useUserRoleState } from "./state";
import { UserManagementTenantRoleEnum } from "@/v2/enum/user-management";
import { t } from "@/i18n";

const css = bemBuilder("user-role");

export default defineComponent({
  name: "UserRole",
  components: {
    AtomText,
    AtomRadio,
    UserRoleAccess,
  },
  setup() {
    const { role, setRole } = useUserRoleState();

    const roleListing = ref<Array<string>>([
      UserManagementTenantRoleEnum.ADMINISTRATOR,
      UserManagementTenantRoleEnum.FINANCE,
      UserManagementTenantRoleEnum.MANAGER,
      UserManagementTenantRoleEnum.MARKETING,
      UserManagementTenantRoleEnum.STAFF,
      UserManagementTenantRoleEnum.HOSTESS,
      UserManagementTenantRoleEnum.WAITER,
    ]);

    function roleTitle(roleItem: UserManagementTenantRoleEnum): string {
      const map = {
        [UserManagementTenantRoleEnum.ACCOUNT_OWNER]: t(
          "c.user_management.roles.account_owner"
        ),
        [UserManagementTenantRoleEnum.ADMINISTRATOR]: t(
          "c.user_management.roles.administrator"
        ),
        [UserManagementTenantRoleEnum.FINANCE]: t(
          "c.user_management.roles.finance"
        ),
        [UserManagementTenantRoleEnum.MANAGER]: t(
          "c.user_management.roles.manager"
        ),
        [UserManagementTenantRoleEnum.MARKETING]: t(
          "c.user_management.roles.marketing"
        ),
        [UserManagementTenantRoleEnum.STAFF]: t(
          "c.user_management.roles.staff"
        ),
        [UserManagementTenantRoleEnum.SUPER_ADMIN]: t(
          "c.user_management.roles.super_admin"
        ),
        [UserManagementTenantRoleEnum.HOSTESS]: t(
          "c.user_management.roles.hostess"
        ),
        [UserManagementTenantRoleEnum.WAITER]: t(
          "c.user_management.roles.waiter"
        ),
      };
      return map[roleItem];
    }

    function roleDescription(roleItem: UserManagementTenantRoleEnum): string {
      const map = {
        [UserManagementTenantRoleEnum.ACCOUNT_OWNER]: "",
        [UserManagementTenantRoleEnum.ADMINISTRATOR]: t(
          "c.user_management.roles.administrator_description"
        ),
        [UserManagementTenantRoleEnum.FINANCE]: t(
          "c.user_management.roles.finance_description"
        ),
        [UserManagementTenantRoleEnum.MANAGER]: t(
          "c.user_management.roles.manager_description"
        ),
        [UserManagementTenantRoleEnum.MARKETING]: t(
          "c.user_management.roles.marketing_description"
        ),
        [UserManagementTenantRoleEnum.STAFF]: t(
          "c.user_management.roles.staff_description"
        ),
        [UserManagementTenantRoleEnum.SUPER_ADMIN]: "",
        [UserManagementTenantRoleEnum.HOSTESS]: t(
          "c.user_management.roles.hostess_description"
        ),
        [UserManagementTenantRoleEnum.WAITER]: t(
          "c.user_management.roles.waiter_description"
        ),
      };
      return map[roleItem];
    }

    return {
      css,
      t,
      AtomTextTypeEnum,
      AtomTextColorEnum,
      role,
      roleListing,
      setRole,
      roleTitle,
      roleDescription,
    };
  },
});
