


















import { bemBuilder } from "@/v2/util/bem-builder";
import { defineComponent, ref, computed } from "@vue/composition-api";
import { IUserManagementListingItemDeleteProps } from "./props";
import { AtomIcon, MolModalConfirm } from "@/v2/new-design-system";
import { string, boolean } from "@/v2/util/prop/type";
import { userManagementMethodDeleteUser } from "../method";
import { useUserManagementState } from "../state";
// TODO: migrate to the new Design System when it becomes available
import { Toast } from "@/design-system";
import { t } from "@/i18n";

const css = bemBuilder("user-management-listing-item-delete");

export default defineComponent<IUserManagementListingItemDeleteProps>({
  name: "UserManagementListingItemDelete",
  components: {
    AtomIcon,
    MolModalConfirm,
  },
  props: {
    id: string().isRequired().use(),
    fullName: string().use(),
    email: string().isRequired().use(),
    isInvite: boolean().use(),
  },
  setup(props) {
    const { organization, deleteInvite, deleteTenant } =
      useUserManagementState();

    const showModalDelete = ref<boolean>(false);

    const titleDeleteModal = computed((): string => {
      if (props.isInvite) {
        return t("c.user_management.delete_modal.title_invite", {
          email: props.email,
        });
      }

      return t("c.user_management.delete_modal.title", {
        name: props.fullName,
      });
    });

    const labelDeleteModal = computed((): string => {
      if (props.isInvite) {
        return t("c.user_management.delete_modal.label_invite");
      }

      return t("c.user_management.delete_modal.label");
    });

    function openModalDelete(): void {
      showModalDelete.value = true;
    }

    function closeModalDelete(): void {
      showModalDelete.value = false;
    }

    async function onDelete(): Promise<void> {
      try {
        await userManagementMethodDeleteUser(
          props.id,
          organization.value.id,
          props.isInvite
        );

        props.isInvite ? deleteInvite(props.id) : deleteTenant(props.id);

        new Toast().create({
          type: "success",
          title: t("label.success"),
          text: t("c.user_management.delete_modal.success"),
        });
      } catch (e) {
        new Toast().create({
          type: "error",
          title: t("label.oops"),
          text: t("message.general_failure"),
        });
      } finally {
        closeModalDelete();
      }
    }

    return {
      css,
      t,
      onDelete,
      showModalDelete,
      openModalDelete,
      closeModalDelete,
      titleDeleteModal,
      labelDeleteModal,
    };
  },
});
