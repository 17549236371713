import { fetchTenants } from "@/v2/repo/fetch-tenants";
import { useUserManagementState } from "../state";
import { IUserManagementListingOrderByProps } from "@/v2/module/user-management/listing/props";

export async function userManagementMethodGetTenants(
  organizationId: string,
  orderBy: IUserManagementListingOrderByProps,
  page: number,
  first: number
): Promise<void> {
  const { setTenants, setTenantsPaginator } = useUserManagementState();

  try {
    const { list, paginator } = await fetchTenants(
      organizationId,
      orderBy,
      page,
      first
    );
    setTenants(list);
    setTenantsPaginator(paginator);
  } catch (error: any) {
    throw new Error(error);
  }
}
