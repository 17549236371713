





























































import { bemBuilder } from "@/v2/util/bem-builder";
import {
  defineComponent,
  ref,
  onMounted,
  computed,
} from "@vue/composition-api";
import {
  AtomText,
  AtomTextTypeEnum,
  AtomTextColorEnum,
  AtomButton,
  AtomButtonSizeEnum,
  MolBackNav,
  MolFormControl,
  MolInputEmailTag,
} from "@/v2/new-design-system";
// TODO: migrate to the new Design System when it becomes available
import { Toast } from "@/design-system";
import UserRole from "../components/user-role/UserRole.vue";
import { UserManagementTenantRoleEnum } from "@/v2/enum/user-management";
import {
  userManagementMethodLoadOrganization,
  userManagementMethodTrack,
} from "../method";
import { useUserManagementState } from "../state";
import { useUserRoleState } from "../components/user-role/state";
import { createInvite } from "@/v2/repo/create-invite";
import { generalErrorToast } from "@/v2/util/general-error-toast";
import { t, tc } from "@/i18n";

const css = bemBuilder("user-management-invite");

export default defineComponent({
  name: "UserManagementInvite",
  components: {
    AtomText,
    AtomButton,
    MolBackNav,
    MolFormControl,
    MolInputEmailTag,
    UserRole,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
  },
  setup(props, context) {
    onMounted(() => {
      clearUser();
      clearRole();
      clearAccessIds();
      clearAccessType();
      userManagementMethodLoadOrganization(props.businessId);

      trackEvent("User invite started");
    });

    const { organization, organizations, clearUser } = useUserManagementState();

    const {
      role,
      accessIds,
      clearRole,
      accessType,
      clearAccessIds,
      clearAccessType,
      canProceed,
    } = useUserRoleState();

    const emails = ref<Array<string>>([]);
    const isLoading = ref<boolean>(false);

    const canSendInvites = computed((): boolean => {
      return (
        Boolean(role?.value) && emails.value.length > 0 && canProceed.value
      );
    });

    const labelButton = computed((): string => {
      if (!canSendInvites.value) {
        return t("c.user_management.invite_page.send_invites");
      }

      const map: Record<string, string> = {
        [UserManagementTenantRoleEnum.ADMINISTRATOR]: tc(
          `c.user_management.invite_page.send_x_administrator_invite`,
          emails.value.length
        ),
        [UserManagementTenantRoleEnum.FINANCE]: tc(
          `c.user_management.invite_page.send_x_finance_invite`,
          emails.value.length
        ),
        [UserManagementTenantRoleEnum.MANAGER]: tc(
          `c.user_management.invite_page.send_x_manager_invite`,
          emails.value.length
        ),
        [UserManagementTenantRoleEnum.MARKETING]: tc(
          `c.user_management.invite_page.send_x_marketing_invite`,
          emails.value.length
        ),
        [UserManagementTenantRoleEnum.STAFF]: tc(
          `c.user_management.invite_page.send_x_staff_invite`,
          emails.value.length
        ),
        [UserManagementTenantRoleEnum.WAITER]: tc(
          `c.user_management.invite_page.send_x_waiter_invite`,
          emails.value.length
        ),
        [UserManagementTenantRoleEnum.HOSTESS]: tc(
          `c.user_management.invite_page.send_x_hostess_invite`,
          emails.value.length
        ),
      };
      return map[role.value];
    });

    async function submitForm() {
      if (!canSendInvites.value) return;

      isLoading.value = true;

      try {
        const res = await createInvite({
          organizationId: organization.value.id,
          emails: emails.value,
          role: role.value,
          accessType: accessType.value,
          accessIds: accessIds.value,
        });
        // @ts-ignore
        setNotification(res);
      } catch (e) {
        trackEvent("User invite not sent", "system failed");
        generalErrorToast();
      } finally {
        isLoading.value = false;
      }
    }

    function setNotification(res: Array<string>) {
      context.root.$router.push({
        name: "user-management.index",
        params: {
          businessId: props.businessId,
        },
      });

      const invitesCreated = emails.value.some((email: string) =>
        res.includes(email)
      );

      if (invitesCreated) {
        new Toast().create({
          type: "success",
          title: t("c.user_management.invite_page.success_toast_title"),
          text: t("c.user_management.invite_page.success_toast_desc"),
        });

        trackEvent("User invite sent");
      }

      const invitesExisting = emails.value.filter(
        (email: string) => !res.includes(email)
      );

      if (invitesExisting.length > 0) {
        new Toast().create({
          type: "warning",
          fixed: true,
          title: t("c.user_management.invite_page.warning_toast_title"),
          text: t("c.user_management.invite_page.warning_toast_desc", {
            emails: invitesExisting,
          }),
        });

        if (!invitesCreated) {
          trackEvent("User invite not sent", "user already exist");
        }
      }
    }

    function trackEvent(eventName: string, notSentReason?: string): void {
      const businessesData = context.root.$store.getters["businesses/getData"];
      const currentBusiness = businessesData.find(
        (business: any) => business.id === props.businessId
      );

      const userData = context.root.$store.getters["auth/getData"];

      const additionalData = {
        business_id: currentBusiness.id,
        business_name: currentBusiness.name,
        id: userData.id,
        roles: userData.roles,
      };

      if (notSentReason) {
        // @ts-ignore
        additionalData.invite_not_sent_reason = notSentReason;
      }

      userManagementMethodTrack(context, eventName, additionalData);
    }

    return {
      css,
      t,
      AtomTextTypeEnum,
      AtomTextColorEnum,
      AtomButtonSizeEnum,
      organization,
      organizations,
      isLoading,
      emails,
      canSendInvites,
      labelButton,
      submitForm,
    };
  },
});
