import { deleteInvite } from "@/v2/repo/delete-invite";
import { deleteTenant } from "@/v2/repo/delete-tenant";

export async function userManagementMethodDeleteUser(
  id: string,
  organizationId: string,
  isInvite: boolean
): Promise<void> {
  const request = { id, organizationId };
  try {
    isInvite ? await deleteInvite(request) : await deleteTenant(request);
  } catch (error: any) {
    throw new Error(error);
  }
}
