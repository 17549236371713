import { graphql } from "../graphql";
import mutation from "./mutation.gql";
import { FetchResult } from "@apollo/client";
import { graphqlExtractGraphqlErrors } from "@/v2/util/graphql-extract-graphql-errors";
import { IRepoErrors } from "../errors";
import { graphqlRemapValidationErrors } from "@/v2/util/graphql-remap-validation-errors";

export interface IAcceptInviteRequest {
  firstName: string;
  lastName: string;
  countryCode: string;
  phoneNumber: string;
  password: string;
}

export async function acceptInvite(
  token: string,
  isTenantExist: boolean,
  request: IAcceptInviteRequest
): Promise<IAcceptInviteResponse> {
  let response: FetchResult<any, Record<string, any>, Record<string, any>>;
  const errors: IRepoErrors<ValidationErrors> = {};

  let tenantAttributes = null;

  if (!isTenantExist) {
    tenantAttributes = {
      firstName: request.firstName,
      lastName: request.lastName,
      countryCode: request.countryCode,
      phoneNumber: request.phoneNumber,
      password: request.password,
    };
  }

  try {
    response = await graphql.mutate({
      mutation,
      variables: {
        token: token,
        tenantAttributes: tenantAttributes,
      },
    });
  } catch (error) {
    errors.networkError = true;
    throw new AcceptInviteError(errors);
  }

  if (response.errors) {
    errors.graphqlError = graphqlExtractGraphqlErrors(response);
    errors.validationError = graphqlRemapValidationErrors(response);

    throw new AcceptInviteError(errors);
  }

  return response.data.acceptInvite;
}

export class AcceptInviteError extends Error {
  errors: IRepoErrors<ValidationErrors>;

  constructor(errors: IRepoErrors<ValidationErrors>) {
    super("Error in repo: acceptInviteError");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}

type ValidationErrors = {};

export interface IAcceptInviteResponse {
  acceptInvite: IAcceptInvite | null;
}

interface IAcceptInvite {
  email: string;
  hash: string;
}
