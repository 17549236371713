



















import { bemBuilder } from "@/v2/util/bem-builder";
import { defineComponent, ref, computed } from "@vue/composition-api";
import { IUserManagementEditDeleteProps } from "./props";
import { MolDeleteButton, MolModalConfirm } from "@/v2/new-design-system";
import { AtomButtonSizeEnum } from "@/v2/new-design-system/atom/button/size.enum";
import { AtomButtonTypeEnum } from "@/v2/new-design-system/atom/button/type.enum";
import { string, boolean } from "@/v2/util/prop/type";
import { userManagementMethodDeleteUser } from "../method";
import { generalErrorToast } from "@/v2/util/general-error-toast";
import { useUserManagementState } from "../state";
// TODO: migrate to the new Design System when it becomes available
import { Toast } from "@/design-system";
import { t } from "@/i18n";

const css = bemBuilder("user-management-listing-item-delete");

export default defineComponent<IUserManagementEditDeleteProps>({
  name: "UserManagementEditDelete",
  components: {
    MolDeleteButton,
    MolModalConfirm,
  },
  props: {
    businessId: string().isRequired().use(),
    id: string().isRequired().use(),
    fullName: string().use(),
    email: string().isRequired().use(),
    isInvite: boolean().setDefault(false).use(),
  },
  setup(props, context) {
    const { organization } = useUserManagementState();

    const showModalDelete = ref<boolean>(false);

    const titleDeleteModal = computed((): string => {
      if (props.isInvite) {
        return t("c.user_management.delete_modal.title_invite", {
          email: props.email,
        });
      }

      return t("c.user_management.delete_modal.title", {
        name: props.fullName,
      });
    });

    const labelDeleteModal = computed((): string => {
      if (props.isInvite) {
        return t("c.user_management.delete_modal.label_invite");
      }

      return t("c.user_management.delete_modal.label");
    });

    function openModalDelete(): void {
      showModalDelete.value = true;
    }

    function closeModalDelete(): void {
      showModalDelete.value = false;
    }

    async function onDelete(): Promise<void> {
      try {
        await userManagementMethodDeleteUser(
          props.id,
          organization.value.id,
          props.isInvite
        );

        context.root.$router.push({
          name: "user-management.index",
          params: {
            businessId: props.businessId,
          },
        });

        new Toast().create({
          type: "success",
          title: t("label.success"),
          text: t("c.user_management.delete_modal.success"),
        });
      } catch (e) {
        generalErrorToast();
      } finally {
        closeModalDelete();
      }
    }

    return {
      css,
      t,
      AtomButtonSizeEnum,
      AtomButtonTypeEnum,
      onDelete,
      showModalDelete,
      openModalDelete,
      closeModalDelete,
      titleDeleteModal,
      labelDeleteModal,
    };
  },
});
