import { fetchOrganizations } from "@/v2/repo/fetch-organizations";
import {
  useUserManagementState,
  IOrganizationItem,
  IOrganizationItemBusiness,
} from "../state";

export async function userManagementMethodLoadOrganization(
  businessId: string
): Promise<void> {
  const { setOrganizations, setOrganization, organization } =
    useUserManagementState();

  try {
    const organizations = await fetchOrganizations();
    setOrganizations(organizations);

    const findOrganization = organizations.find((obj: IOrganizationItem) => {
      obj.businesses.find((obj: IOrganizationItemBusiness) => {
        return obj.id === businessId;
      });
    });

    const isExistingOrganization = organizations.some(
      (obj: IOrganizationItem) => obj.id === organization.value.id
    );

    if (!organization.value.id || !isExistingOrganization) {
      setOrganization(findOrganization ? findOrganization : organizations[0]);
    }
  } catch (error: any) {
    throw new Error(error);
  }
}
