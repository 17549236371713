




















































import { bemBuilder } from "@/v2/util/bem-builder";
import { defineComponent, watch } from "@vue/composition-api";
import {
  AtomIcon,
  AtomText,
  AtomTextTypeEnum,
  AtomTextColorEnum,
  AtomAnchor,
  AtomAnchorTypeEnum,
  AtomAnchorSizeEnum,
  MolGuideLink,
  MolGuideLinkArticleEnum,
  OrgHeaderInfo,
} from "@/v2/new-design-system";
import SwitchOrganization from "../components/SwitchOrganization.vue";
import { userManagementMethodTrack } from "../method";
import { UserManagementListing } from "../listing";
import { useUserManagementState } from "../state";
import { t } from "@/i18n";

const css = bemBuilder("user-management");

export default defineComponent({
  name: "UserManagementMain",
  components: {
    AtomAnchor,
    AtomIcon,
    AtomText,
    SwitchOrganization,
    UserManagementListing,
    MolGuideLink,
    OrgHeaderInfo,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
  },
  setup(props, context) {
    const { organizations, organization, setCanSeeUsers, canSeeUsers } =
      useUserManagementState();

    watch(
      () => organization.value,
      () => {
        setCanSeeUsers(true);
      },
      { immediate: true }
    );

    function trackEvent() {
      const businessesData = context.root.$store.getters["businesses/getData"];
      const currentBusiness = businessesData.find(
        (business: any) => business.id === props.businessId
      );

      const userData = context.root.$store.getters["auth/getData"];

      const additionalData = {
        business_id: currentBusiness.id,
        business_name: currentBusiness.name,
        id: userData.id,
        roles: userData.roles,
        label: "How to manage users",
      };

      userManagementMethodTrack(context, "Clicked on Button", additionalData);
    }

    return {
      css,
      t,
      canSeeUsers,
      organizations,
      organization,
      trackEvent,
      AtomTextTypeEnum,
      AtomTextColorEnum,
      AtomAnchorTypeEnum,
      AtomAnchorSizeEnum,
      MolGuideLinkArticleEnum,
    };
  },
});
