import { fetchInvites } from "@/v2/repo/fetch-invites";
import { useUserManagementState } from "../state";

export async function userManagementMethodGetInvites(
  organizationId: string
): Promise<void> {
  const { setInvites } = useUserManagementState();

  try {
    const invites = await fetchInvites(organizationId);
    setInvites(invites);
  } catch (error: any) {
    throw new Error(error);
  }
}
