
























import { bemBuilder } from "@/v2/util/bem-builder";
import {
  defineComponent,
  ref,
  onMounted,
  computed,
} from "@vue/composition-api";
import {
  AtomLoading,
  MolSearchBox,
  OrgCollapsableSelectList,
  OrgSelectList,
} from "@/v2/new-design-system";
import { fetchOrganizations } from "@/v2/repo/fetch-organizations";
import { generalErrorToast } from "@/v2/util/general-error-toast";
import { useUserRoleState } from "./state";
import { useUserManagementState } from "../../state";
import { t } from "@/i18n";

const css = bemBuilder("user-role-selected-access");

export default defineComponent({
  name: "UserRoleSelectedAccess",
  components: {
    AtomLoading,
    MolSearchBox,
    OrgCollapsableSelectList,
    OrgSelectList,
  },
  setup() {
    const { accessIds, setAccessIds, isAdministratorRole } = useUserRoleState();
    const { organization } = useUserManagementState();

    const filterItems = ref<string>("");
    const isLoading = ref<boolean>(false);
    const businesses = ref([]);

    onMounted(async () => {
      isLoading.value = true;

      try {
        const organizationFound = await fetchOrganizations(
          organization.value.id,
          999
        );
        buildBusinessData(organizationFound[0].businesses);
      } catch (e) {
        generalErrorToast();
      } finally {
        isLoading.value = false;
      }
    });

    function buildBusinessData(businessListing: any): void {
      businessListing.forEach((obj: any) => {
        if (!isAdministratorRole.value) {
          obj.items = obj.outlets.data;
          obj.items.forEach((objItem: any) => {
            objItem.selected = false;
          });
        }
        delete obj.outlets;
      });
      businesses.value = businessListing;
    }

    const componentName = computed((): string => {
      return isAdministratorRole.value
        ? "OrgSelectList"
        : "OrgCollapsableSelectList";
    });

    const placeholderLabel = computed((): string => {
      return isAdministratorRole.value
        ? t("c.user_management.access.search_businesses")
        : t("c.user_management.access.search_outlets");
    });

    return {
      css,
      t,
      isLoading,
      filterItems,
      componentName,
      placeholderLabel,
      businesses,
      setAccessIds,
      accessIds,
    };
  },
});
