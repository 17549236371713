










import { bemBuilder } from "@/v2/util/bem-builder";
import { defineComponent, onMounted } from "@vue/composition-api";
import { MolDropdown } from "@/v2/new-design-system";
import { useUserManagementState, IOrganizationItem } from "../state";
import { userManagementMethodLoadOrganization } from "../method";
import { string } from "@/v2/util/prop/type";

const css = bemBuilder("switch-organizations");

export default defineComponent({
  name: "SwitchOrganization",
  components: {
    MolDropdown,
  },
  props: {
    businessId: string().isRequired().use(),
  },
  setup(props) {
    const { setCanSeeUsers, organizations, organization, setOrganization } =
      useUserManagementState();

    onMounted(async () => {
      setCanSeeUsers(true);
      await userManagementMethodLoadOrganization(props.businessId);
    });

    function selectItem(id: string) {
      const organizationSelected = organizations.value.find(
        (obj: IOrganizationItem) => obj.id === id
      );
      setOrganization(organizationSelected);
    }

    return {
      css,
      organizations,
      organization,
      selectItem,
    };
  },
});
