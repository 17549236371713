import { graphql } from "../graphql";
import query from "./query.gql";
import { IRepoErrors } from "../errors";
import { FetchResult } from "@apollo/client";
import { graphqlExtractGraphqlErrors } from "@/v2/util/graphql-extract-graphql-errors";
import { graphqlRemapValidationErrors } from "@/v2/util/graphql-remap-validation-errors";
import {
  UserManagementTenantRoleEnum,
  UserManagementUserAccessTypeEnum,
} from "@/v2/enum/user-management";

interface IFetchTenantRequest {
  id: string;
  organizationId: string;
}

export async function fetchTenant(
  request: IFetchTenantRequest
): Promise<IFetchTenantResponse> {
  let response: FetchResult<any, Record<string, any>, Record<string, any>>;
  const errors: IRepoErrors<ValidationErrors> = {};

  try {
    response = await graphql.query({
      query,
      variables: {
        id: request.id,
        organizationId: request.organizationId,
      },
    });
  } catch (error) {
    errors.networkError = true;
    throw new FetchTenantError(errors);
  }

  if (response.errors) {
    errors.graphqlError = graphqlExtractGraphqlErrors(response);
    errors.validationError = graphqlRemapValidationErrors(response);
    throw new FetchTenantError(errors);
  }

  return response.data.tenant;
}

export class FetchTenantError extends Error {
  errors: IRepoErrors<ValidationErrors>;

  constructor(errors: IRepoErrors<ValidationErrors>) {
    super("Error in repo: FetchTenant");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}

type ValidationErrors = {};

interface IFetchTenantResponse {
  id: string;
  fullName: string;
  email: string;
  phone: string;
  role: UserManagementTenantRoleEnum;
  accessibleResources: IAccessibleResources;
}

interface IAccessibleResources {
  accessType: UserManagementUserAccessTypeEnum;
  accessIds: Array<string>;
}
