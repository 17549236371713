import { graphql } from "../graphql";
import query from "./query.gql";
import { IRepoErrors } from "../errors";
import { FetchResult } from "@apollo/client";
import { graphqlExtractGraphqlErrors } from "@/v2/util/graphql-extract-graphql-errors";
import { graphqlRemapValidationErrors } from "@/v2/util/graphql-remap-validation-errors";
import { UserManagementTenantRoleEnum } from "@/v2/enum/user-management";

export async function fetchInviteByToken(
  token: string
): Promise<IFetchInviteByTokenResponse> {
  let response: FetchResult<any, Record<string, any>, Record<string, any>>;
  const errors: IRepoErrors<ValidationErrors> = {};

  try {
    response = await graphql.query({
      query,
      variables: {
        token,
      },
    });
  } catch (error) {
    errors.networkError = true;
    throw new FetchInviteByTokenError(errors);
  }

  if (response.errors) {
    errors.graphqlError = graphqlExtractGraphqlErrors(response);
    errors.validationError = graphqlRemapValidationErrors(response);
    throw new FetchInviteByTokenError(errors);
  }

  return {
    invite: response.data.inviteByToken.invite,
    isTenantExist: response.data.inviteByToken.isTenantExist,
  };
}

export class FetchInviteByTokenError extends Error {
  errors: IRepoErrors<ValidationErrors>;

  constructor(errors: IRepoErrors<ValidationErrors>) {
    super("Error in repo: FetchInviteByToken");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}

type ValidationErrors = {};

interface IFetchInviteByTokenResponse {
  invite: {
    email: string;
    role: UserManagementTenantRoleEnum;
    invitee: IInvitee;
    businesses: Ibusinesses;
  };
  isTenantExist: boolean;
}

interface IInvitee {
  firstName: string;
  lastName: string;
}

interface Ibusinesses {
  name: string;
  logo: string;
  outlets: Array<string>;
}
