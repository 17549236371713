
































import { bemBuilder } from "@/v2/util/bem-builder";
import {
  defineComponent,
  ref,
  computed,
  onMounted,
  watch,
} from "@vue/composition-api";
import {
  AtomText,
  AtomTextTypeEnum,
  AtomTextColorEnum,
  AtomRadio,
} from "@/v2/new-design-system";
import UserRoleSelectedAccess from "./UserRoleSelectedAccess.vue";
import { useUserManagementState } from "../../state";
import { useUserRoleState } from "./state";
import {
  UserManagementTenantRoleEnum,
  UserManagementUserAccessEnum,
  UserManagementUserAccessTypeEnum,
} from "@/v2/enum/user-management";
import { t } from "@/i18n";

const css = bemBuilder("user-role-access");

export default defineComponent({
  name: "UserRoleAccess",
  components: {
    AtomText,
    AtomRadio,
    UserRoleSelectedAccess,
  },
  setup() {
    const {
      role,
      accessIds,
      clearAccessIds,
      setAccessType,
      setAccessIds,
      setCanProceed,
    } = useUserRoleState();

    const { organization, user } = useUserManagementState();

    const access = ref<UserManagementUserAccessEnum>(
      UserManagementUserAccessEnum.ALL_ACCESS
    );

    const isAllAccess = computed((): boolean => {
      return access.value === UserManagementUserAccessEnum.ALL_ACCESS;
    });

    function defineTitle(accessTab: UserManagementUserAccessEnum): string {
      const map = {
        [UserManagementUserAccessEnum.ALL_ACCESS]: t(
          "c.user_management.access.all_access"
        ),
        [UserManagementUserAccessEnum.SELECTED_ACCESS]: t(
          "c.user_management.access.selected_access"
        ),
      };
      return map[accessTab];
    }

    function defineDescription(
      accessTab: UserManagementUserAccessEnum,
      roleTab: UserManagementTenantRoleEnum
    ): string {
      const tabIsAllAccess =
        accessTab === UserManagementUserAccessEnum.ALL_ACCESS;

      const map = {
        [UserManagementTenantRoleEnum.ACCOUNT_OWNER]: "",
        [UserManagementTenantRoleEnum.ADMINISTRATOR]: tabIsAllAccess
          ? t("c.user_management.access.all_access_description_administrator")
          : t(
              "c.user_management.access.selected_access_description_administrator"
            ),
        [UserManagementTenantRoleEnum.FINANCE]: tabIsAllAccess
          ? t("c.user_management.access.all_access_description_finance")
          : t("c.user_management.access.selected_access_description_finance"),
        [UserManagementTenantRoleEnum.MANAGER]: tabIsAllAccess
          ? t("c.user_management.access.all_access_description_manager")
          : t("c.user_management.access.selected_access_description_manager"),
        [UserManagementTenantRoleEnum.MARKETING]: tabIsAllAccess
          ? t("c.user_management.access.all_access_description_marketing")
          : t("c.user_management.access.selected_access_description_marketing"),
        [UserManagementTenantRoleEnum.STAFF]: tabIsAllAccess
          ? t("c.user_management.access.all_access_description_staff")
          : t("c.user_management.access.selected_access_description_staff"),
        [UserManagementTenantRoleEnum.SUPER_ADMIN]: "",
        [UserManagementTenantRoleEnum.WAITER]: tabIsAllAccess
          ? t("c.user_management.access.all_access_description_waiter")
          : t("c.user_management.access.selected_access_description_waiter"),
        [UserManagementTenantRoleEnum.HOSTESS]: tabIsAllAccess
          ? t("c.user_management.access.all_access_description_hostess")
          : t("c.user_management.access.selected_access_description_hostess"),
      };
      return map[roleTab];
    }

    const isSelectedAccess = computed((): boolean => {
      return access.value === UserManagementUserAccessEnum.SELECTED_ACCESS;
    });

    const canProceed = computed((): boolean => {
      return (
        isAllAccess.value || (!isAllAccess.value && accessIds.value.length > 0)
      );
    });

    onMounted(() => {
      if (
        user.value.role &&
        user.value.role === role.value &&
        user.value.accessibleResources.accessType !==
          UserManagementUserAccessTypeEnum.ORGANIZATION
      ) {
        access.value = UserManagementUserAccessEnum.SELECTED_ACCESS;
        return;
      }

      defineAccess();
    });

    watch(
      () => accessIds.value,
      () => {
        redefineState();
      }
    );

    function defineAccess(accessItem?: UserManagementUserAccessEnum): void {
      if (accessItem) {
        access.value = accessItem;
      }

      if (isAllAccess.value) {
        setAccessIds([organization.value.id]);
      }

      if (isSelectedAccess.value) {
        clearAccessIds();
      }

      redefineState();
    }

    const defineAccessType = computed(() => {
      if (isAllAccess.value) {
        return UserManagementUserAccessTypeEnum.ORGANIZATION;
      }

      const map: Record<string, string> = {
        [UserManagementTenantRoleEnum.ADMINISTRATOR]:
          UserManagementUserAccessTypeEnum.BUSINESS,
        [UserManagementTenantRoleEnum.FINANCE]:
          UserManagementUserAccessTypeEnum.OUTLET,
        [UserManagementTenantRoleEnum.MANAGER]:
          UserManagementUserAccessTypeEnum.OUTLET,
        [UserManagementTenantRoleEnum.MARKETING]:
          UserManagementUserAccessTypeEnum.OUTLET,
        [UserManagementTenantRoleEnum.STAFF]:
          UserManagementUserAccessTypeEnum.OUTLET,
        [UserManagementTenantRoleEnum.WAITER]:
          UserManagementUserAccessTypeEnum.OUTLET,
        [UserManagementTenantRoleEnum.HOSTESS]:
          UserManagementUserAccessTypeEnum.OUTLET,
      };
      return map[role.value];
    });

    function redefineState(): void {
      setCanProceed(canProceed.value);
      setAccessType(defineAccessType.value);
    }

    return {
      css,
      t,
      AtomTextTypeEnum,
      AtomTextColorEnum,
      UserManagementUserAccessEnum,
      access,
      role,
      defineTitle,
      defineDescription,
      defineAccess,
      isSelectedAccess,
    };
  },
});
