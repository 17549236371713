





















import { bemBuilder } from "@/v2/util/bem-builder";
import { defineComponent, ref, onMounted } from "@vue/composition-api";
import {
  AtomText,
  MolLoadingZone,
  OrgTipsSlider,
} from "@/v2/new-design-system";
import { AtomTextTypeEnum } from "@/v2/new-design-system/atom/text/type.enum";
import { AtomTextColorEnum } from "@/v2/new-design-system/atom/text/color.enum";
import { AcceptForm, AcceptAccess } from "./";
import { userManagementMethodTrack } from "../../method";
import { fetchInviteByToken } from "@/v2/repo/fetch-invite-token";
import { useAcceptInviteState } from "./state";
import { string } from "@/v2/util/prop/type";
import { t } from "@/i18n";

const css = bemBuilder("accept-invite");

export default defineComponent({
  name: "AcceptInvite",
  components: {
    AtomText,
    AcceptForm,
    AcceptAccess,
    MolLoadingZone,
    OrgTipsSlider,
  },
  props: {
    token: string().isRequired().use(),
  },
  setup(props, context) {
    const {
      firstStep,
      setToken,
      isInvalidInvite,
      setIsInvalidInvite,
      inviteData,
      setInviteData,
      isTenantExist,
      setIsTenantExist,
      switchStep,
    } = useAcceptInviteState();

    const loadingZone = ref<boolean>(false);

    onMounted(() => {
      setToken(props.token);
      getInviteByToken();
    });

    async function getInviteByToken() {
      loadingZone.value = true;

      try {
        const { isTenantExist, invite } = await fetchInviteByToken(props.token);
        context.root.$store.dispatch("auth/logout");
        setIsTenantExist(isTenantExist);
        setInviteData(invite);
        trackEvent();
      } catch (e) {
        setIsInvalidInvite(true);
      } finally {
        loadingZone.value = false;
      }
    }

    function trackEvent(): void {
      userManagementMethodTrack(context, "User invite accepted", {
        roles: [inviteData.value.role.toLowerCase()],
      });
    }

    return {
      t,
      css,
      AtomTextTypeEnum,
      AtomTextColorEnum,
      loadingZone,
      isInvalidInvite,
      firstStep,
    };
  },
});
