
































import { bemBuilder } from "@/v2/util/bem-builder";
import {
  defineComponent,
  ref,
  computed,
  onMounted,
} from "@vue/composition-api";
import { AtomButton, AtomText, OrgFormInput } from "@/v2/new-design-system";
import { AtomTextTypeEnum } from "@/v2/new-design-system/atom/text/type.enum";
import { AtomTextColorEnum } from "@/v2/new-design-system/atom/text/color.enum";
import { AtomButtonSizeEnum } from "@/v2/new-design-system/atom/button/size.enum";
// TODO: migrate to the new Design System when it becomes available
import { Toast } from "@/design-system";
import { useTenantProfileState } from "./state";
import { updateTenantPersonalInfo } from "@/v2/repo/update-tenant-personal-info";
import { generalErrorToast } from "@/v2/util/general-error-toast";
import { t } from "@/i18n";

const css = bemBuilder("user-management-profile-personal-info");

interface IProfileData {
  firstName: string;
  lastName: string;
}

export default defineComponent({
  name: "UserManagementProfilePersonalInfo",
  components: {
    AtomButton,
    AtomText,
    OrgFormInput,
  },
  setup(_, context) {
    const loadingButton = ref<boolean>(false);

    const { profileData } = useTenantProfileState();

    const initialPersonalInfo = ref<IProfileData>({
      firstName: "",
      lastName: "",
    });

    const personalInfo = ref<IProfileData>({
      firstName: "",
      lastName: "",
    });

    onMounted(() => {
      initialData();
    });

    function initialData(): void {
      ["firstName", "lastName"].forEach(
        (item: string) =>
          // @ts-ignore
          (initialPersonalInfo.value[item] = profileData.value[item])
      );
      personalInfo.value = { ...initialPersonalInfo.value };
    }

    const canUpdate = computed((): boolean => {
      return (
        Boolean(personalInfo.value.firstName && personalInfo.value.lastName) &&
        Boolean(
          personalInfo.value.firstName !==
            initialPersonalInfo.value.firstName ||
            personalInfo.value.lastName !== initialPersonalInfo.value.lastName
        )
      );
    });

    async function updatePersonalInfo() {
      loadingButton.value = true;

      try {
        await updateTenantPersonalInfo(personalInfo.value);

        initialPersonalInfo.value = { ...personalInfo.value };

        context.root.$store.dispatch(
          "auth/updateUserProfile",
          initialPersonalInfo.value
        );

        new Toast().create({
          type: "success",
          title: t("c.user_management.profile_page.success_toast_title"),
          text: t("c.user_management.profile_page.success_toast_desc"),
        });
      } catch (e) {
        generalErrorToast();
        personalInfo.value = { ...initialPersonalInfo.value };
      } finally {
        loadingButton.value = false;
      }
    }

    return {
      css,
      t,
      AtomTextTypeEnum,
      AtomTextColorEnum,
      AtomButtonSizeEnum,
      loadingButton,
      canUpdate,
      personalInfo,
      initialPersonalInfo,
      updatePersonalInfo,
    };
  },
});
