

























































import { bemBuilder } from "@/v2/util/bem-builder";
import { defineComponent, ref, computed } from "@vue/composition-api";
import {
  AtomButton,
  AtomText,
  MolFormControl,
  MolInputPassword,
} from "@/v2/new-design-system";
import { AtomTextTypeEnum } from "@/v2/new-design-system/atom/text/type.enum";
import { AtomTextColorEnum } from "@/v2/new-design-system/atom/text/color.enum";
import { AtomButtonSizeEnum } from "@/v2/new-design-system/atom/button/size.enum";
// TODO: migrate to the new Design System when it becomes available
import { Toast } from "@/design-system";
import { updateTenantPassword } from "@/v2/repo/update-tenant-password";
import { generalErrorToast } from "@/v2/util/general-error-toast";
import { t } from "@/i18n";

const css = bemBuilder("user-management-profile-security");

interface ISecurity {
  password: string;
  newPassword: string;
  confirmPassword: string;
}

export default defineComponent({
  name: "UserManagementProfilePassword",
  components: {
    AtomButton,
    AtomText,
    MolFormControl,
    MolInputPassword,
  },
  setup() {
    const loadingButton = ref<boolean>(false);

    let initialStates = ref<ISecurity>({
      password: "",
      newPassword: "",
      confirmPassword: "",
    });

    const securityStates = ref<ISecurity>(
      JSON.parse(JSON.stringify(initialStates.value))
    );
    const hasErrors = ref<ISecurity>(
      JSON.parse(JSON.stringify(initialStates.value))
    );

    const passwordMatch = computed((): boolean => {
      return (
        securityStates.value.newPassword ===
        securityStates.value.confirmPassword
      );
    });

    const canUpdate = computed((): boolean => {
      return Boolean(
        securityStates.value.password &&
          securityStates.value.newPassword &&
          securityStates.value.confirmPassword
      );
    });

    async function updatePassword() {
      loadingButton.value = true;
      hasErrors.value = JSON.parse(JSON.stringify(initialStates.value));

      if (!passwordMatch.value) {
        hasErrors.value.confirmPassword = t(
          "c.user_management.profile_page.password_dont_match"
        );
        loadingButton.value = false;
        return;
      }

      if (passwordMatch.value) {
        const request = {
          password: securityStates.value.password,
          newPassword: securityStates.value.newPassword,
        };

        try {
          await updateTenantPassword(request);

          hasErrors.value = JSON.parse(JSON.stringify(initialStates.value));
          securityStates.value = JSON.parse(
            JSON.stringify(initialStates.value)
          );

          new Toast().create({
            type: "success",
            title: t(
              "c.user_management.profile_page.success_toast_title_password"
            ),
            text: t(
              "c.user_management.profile_page.success_toast_desc_password"
            ),
          });
        } catch (error: any) {
          if (error.errors.validationError) {
            hasErrors.value = error.errors.validationError;
            generalErrorToast(
              t("c.user_management.profile_page.error_toast_desc_password")
            );
            return;
          }
          generalErrorToast();
        } finally {
          loadingButton.value = false;
        }
      }
    }

    return {
      css,
      t,
      AtomTextTypeEnum,
      AtomTextColorEnum,
      AtomButtonSizeEnum,
      loadingButton,
      canUpdate,
      securityStates,
      hasErrors,
      updatePassword,
    };
  },
});
