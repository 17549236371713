import { FetchResult } from "@apollo/client";
import { graphqlExtractValidationErrors } from "./graphql-extract-validation-errors";

export function graphqlRemapValidationErrors<T>(
  response: FetchResult<any, Record<string, any>, Record<string, any>>,
  remapConfig?: Array<{
    ifKey: string;
    hasValue: string;
    set: string;
    to: string;
  }>
): T | null {
  const errors = graphqlExtractValidationErrors(response?.errors?.[0]);
  const processedErrors: any = {};

  if (Object.keys(errors).length) {
    remapConfig &&
      remapConfig.forEach((remap) => {
        if (errors[remap.ifKey] === remap.hasValue) {
          processedErrors[remap.set] = remap.set;
          delete errors[remap.ifKey];
        }
      });

    return { ...errors, ...processedErrors } as T;
  }

  return null;
}
