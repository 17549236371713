import { updateInvite } from "@/v2/repo/update-invite";
import { updateTenant } from "@/v2/repo/update-tenant";
import { IUser } from "../edit/props";

export async function userManagementMethodUpdateUser(
  request: IUser,
  isInvite: boolean
): Promise<void> {
  isInvite ? updateInvite(request) : updateTenant(request);
}
