















































import { bemBuilder } from "@/v2/util/bem-builder";
import { defineComponent, PropType, computed } from "@vue/composition-api";
import { AtomIcon, AtomText, AtomTextTypeEnum } from "@/v2/new-design-system";
import { UserManagementTenantRoleEnum } from "@/v2/enum/user-management";
import { imageURLBuilder } from "@/v2/util/image-url-builder";
import { t } from "@/i18n";

const css = bemBuilder("role-list");

export default defineComponent({
  name: "RoleList",
  components: {
    AtomText,
    AtomIcon,
  },
  props: {
    role: {
      type: String as PropType<UserManagementTenantRoleEnum>,
      default: "",
    },
    businesses: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const labelRole = computed((): string => {
      const map = {
        [UserManagementTenantRoleEnum.ACCOUNT_OWNER]: t(
          "c.user_management.roles.account_owner"
        ),
        [UserManagementTenantRoleEnum.ADMINISTRATOR]: t(
          "c.user_management.roles.administrator"
        ),
        [UserManagementTenantRoleEnum.FINANCE]: t(
          "c.user_management.roles.finance"
        ),
        [UserManagementTenantRoleEnum.MANAGER]: t(
          "c.user_management.roles.manager"
        ),
        [UserManagementTenantRoleEnum.MARKETING]: t(
          "c.user_management.roles.marketing"
        ),
        [UserManagementTenantRoleEnum.STAFF]: t(
          "c.user_management.roles.staff"
        ),
        [UserManagementTenantRoleEnum.SUPER_ADMIN]: t(
          "c.user_management.roles.super_admin"
        ),
        [UserManagementTenantRoleEnum.HOSTESS]: t(
          "c.user_management.roles.hostess"
        ),
        [UserManagementTenantRoleEnum.WAITER]: t(
          "c.user_management.roles.waiter"
        ),
      };
      return map[props.role];
    });

    function businessLogo(imagePath: string): string {
      return `${process.env.VUE_APP_S3_BUCKET_PATH}${imagePath}`;
    }

    function labelOutlets(outlets: Array<string>): string {
      return outlets.length > 0
        ? outlets.toString().replace(/,/g, ", ")
        : t("label.all_outlets");
    }

    return {
      css,
      labelRole,
      businessLogo,
      labelOutlets,
      imageURLBuilder,
      AtomTextTypeEnum,
    };
  },
});
