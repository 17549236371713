import { graphql } from "../graphql";
import mutation from "./mutation.gql";
import { FetchResult } from "@apollo/client";
import { graphqlExtractGraphqlErrors } from "@/v2/util/graphql-extract-graphql-errors";
import { IRepoErrors } from "../errors";
import { graphqlRemapValidationErrors } from "@/v2/util/graphql-remap-validation-errors";

export interface IUpdateTenantPersonalInfoRequest {
  firstName: string;
  lastName: string;
}

export async function updateTenantPersonalInfo(
  request: IUpdateTenantPersonalInfoRequest
): Promise<boolean> {
  let response: FetchResult<any, Record<string, any>, Record<string, any>>;
  const errors: IRepoErrors<ValidationErrors> = {};

  try {
    response = await graphql.mutate({
      mutation,
      variables: {
        firstName: request.firstName,
        lastName: request.lastName,
      },
    });
  } catch (error) {
    errors.networkError = true;
    throw new UpdateTenantPersonalInfoError(errors);
  }

  if (response?.errors) {
    errors.graphqlError = graphqlExtractGraphqlErrors(response);
    errors.validationError = graphqlRemapValidationErrors(response);

    throw new UpdateTenantPersonalInfoError(errors);
  }

  return response.data.updateTenantPersonalInfo;
}

export class UpdateTenantPersonalInfoError extends Error {
  errors: IRepoErrors<ValidationErrors>;

  constructor(errors: IRepoErrors<ValidationErrors>) {
    super("Error in repo: UpdateTenantPersonalInfoError");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}

type ValidationErrors = {};
