import { graphql } from "../graphql";
import mutation from "./mutation.gql";
import { FetchResult } from "@apollo/client";
import { graphqlExtractGraphqlErrors } from "@/v2/util/graphql-extract-graphql-errors";
import { IRepoErrors } from "../errors";
import { graphqlRemapValidationErrors } from "@/v2/util/graphql-remap-validation-errors";

export interface IUpdateTenantPasswordRequest {
  password: String;
  newPassword: String;
}

export async function updateTenantPassword(
  request: IUpdateTenantPasswordRequest
): Promise<boolean> {
  let response: FetchResult<any, Record<string, any>, Record<string, any>>;
  const errors: IRepoErrors<ValidationErrors> = {};

  try {
    response = await graphql.mutate({
      mutation,
      variables: {
        password: request.password,
        newPassword: request.newPassword,
      },
    });
  } catch (error) {
    errors.networkError = true;
    throw new UpdateTenantPasswordError(errors);
  }

  if (response.errors) {
    errors.graphqlError = graphqlExtractGraphqlErrors(response);
    errors.validationError = graphqlRemapValidationErrors(response);

    throw new UpdateTenantPasswordError(errors);
  }

  return response.data.editTenantAccess;
}

export class UpdateTenantPasswordError extends Error {
  errors: IRepoErrors<ValidationErrors>;

  constructor(errors: IRepoErrors<ValidationErrors>) {
    super("Error in repo: updateTenantPasswordError");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}

type ValidationErrors = {};
